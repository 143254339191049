// common modal
.common_modal_wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($z-index, modal);
  .dimmed {
    position: static;
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.5);
    // common.css 초기화
    display: block;
    opacity: 1;
    filter: none;
  }
}
.common_modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 350px;
  margin: 0 auto;
  border: 1px solid $color-black;
  border-radius: 12px;
  background: $color-white;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}
.common_modal_header {
  position: relative;
  padding: 19px 50px 15px 20px;
  border-bottom: 1px solid map-get($color-line, line-01);
  .common_modal_close {
    position: absolute;
    top: 50%;
    right: 6px;
    padding: 10px;
    transform: translateY(-50%);
  }
}
.common_modal_title {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: $color-black;
}
.common_modal_content {
  @include scrollbar;
  min-height: 102px;
  max-height: 348px;
  padding: 20px 20px 16px;
  box-sizing: border-box;
}
.common_modal_footer {
  @include clearfix;
  .checkbox_item {
    float: left;
    padding: 19px 20px 17px;
  }
  .common_modal_close {
    float: right;
    padding: 16px 20px;
    margin-left: auto;
    font-size: 14px;
    line-height: 22px;
    color: $color-black;
  }
  .common_btn_box {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
}
.modal_notice {
  font-size: 15px;
  line-height: 22px;
  color: $color-black;
}
.modal_text_notice {
  font-size: 15px;
  line-height: 22px;
  color: $color-black;
}