// common_icon 형태로 재사용가능하게 관리할 아이콘 목록
$icons:
arrow_down
arrow_left
arrow_right
arrow_up
calendar
close
download_white
facebook
facebook_gray
info
instagram_gray
rank_down
rank_up
schedule_empty
search
share
tooltip
twitter
twitter_gray
url
versus
naverblog_gray
youtube_gray
;

.common_icon {
  display: inline-flex;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
  &::after {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: center / 100% no-repeat;
    content: '';
  }
  &.ty_xs {
    width: 16px;
    height: 16px;
  }
  &.ty_sm {
    width: 24px;
    height: 24px;
  }
  &.ty_md {
    width: 32px;
    height: 32px;
  }
  &.ty_lg {
    width: 46px;
    height: 46px;
  }
  &.ty_xl {
    width: 72px;
    height: 72px;
  }
}
@each $icon in $icons {
  .icon_#{$icon}::after {
    background-image: svg-load("../../img/svg/icons/#{$icon}.svg");
  }
}

// arrow btn disabled
button:disabled {
  .icon_arrow_left::after,
  .icon_arrow_right::after {
    @include color-filter(map-get($color-type, gray-02));
  }
}

.icon_arrow_up.ty_xs::after {
  background-image: svg-load("../../img/svg/icons/arrow_up_16.svg");
}
.icon_arrow_down.ty_xs::after {
  background-image: svg-load("../../img/svg/icons/arrow_down_16.svg");
}
.icon_arrow_left.ty_xs::after {
  background-image: svg-load("../../img/svg/icons/arrow_left_16.svg");
}
.icon_arrow_right.ty_xs::after {
  background-image: svg-load("../../img/svg/icons/arrow_right_16.svg");
}

// Logo 별도 관리. 컴포넌트별 width,height 선언
.logo_black_kr {
  width: 126px;
  height: 49px;
  &::after {
    background-image: svg-load('../../img/svg/common/logo_black_kr.svg');
  }
}
// .logo_gray_kr {
//   width: 126px;
//   height: 49px;
//   &::after {
//     background-image: svg-load('../../img/svg/common/logo_gray_kr.svg');
//   }
// }
.logo_payco {
  width: 42px;
  height: 10px;
  &::after {
    background-image: svg-load('../../img/svg/common/logo_payco.svg');
  }
}
.logo_bugs {
  width: 32px;
  height: 13px;
  &::after {
    background-image: svg-load('../../img/svg/common/logo_bugs.svg');
  }
}
.logo_comico {
  width: 47px;
  height: 12px;
  &::after {
    background-image: svg-load('../../img/svg/common/logo_comico.svg');
  }
}
.logo_hangame {
  width: 61px;
  height: 9px;
  &::after {
    background-image: svg-load('../../img/svg/common/logo_hangame.svg');
  }
}
.logo_inside_nhn {
  width: 63px;
  height: 9px;
  &::after {
    background-image: svg-load('../../img/svg/common/logo_inside_nhn.svg');
  }
}
.logo_payco_vip {
  width: 205px;
  height: 32px;
  &::after {
    background-image: svg-load('../../img/svg/common/logo_payco_vip.svg');
  }
}