.common_tab {
  &.type_capsule {
    .common_tab_list {
      margin: -6px 0 0 -6px;
    }
    .common_tab_item {
      margin: 6px 0 0 6px;
    }
    .common_tab_btn {
      padding: 6px 18px 8px;
      min-height: 40px;
      border: 1px solid map-get($color-line, line-04);
      border-radius: 40px;
      background: $color-white;
      font-size: 16px;
      line-height: 24px;
      color: $color-black;
      &[aria-selected="true"] {
        padding: 6px 20px 8px;
        border-color: $color-black;
        background: $color-black;
        color: $color-white;
      }
      &:disabled {
        border-color: map-get($color-background, bg-04);
        background: map-get($color-background, bg-04);
        color: map-get($color-type, gray-02);
        &:hover,
        &:focus {
          border-color: map-get($color-background, bg-04);
        }
      }
      &:hover,
      &:focus {
        border-color: $color-black
      }
    }
    &.type_expand {
      .common_tab_list {
        overflow: hidden;
        margin: 0 0 -6px -6px;
        text-align: right;
        white-space: nowrap;
      }
      .common_tab_item {
        margin: 0 0 6px 6px;
      }
      .tab_expand_btn {
        position: relative;
        float: right;
        width: 40px;
        height: 40px;
        z-index: 1;
        &::before {
          position: absolute;
          top: 0;
          right: 20px;
          width: 65px;
          height: 40px;
          background: linear-gradient(270deg, #FFFFFF 64.58%, rgba(255, 255, 255, 0) 100%);
          z-index: -1;
          content: '';
        }
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid map-get($color-line, line-04);
          border-radius: 50%;
          box-sizing: border-box;
          content: '';
        }
        .icon_arrow_down {
          vertical-align: top;
        }
        &[aria-expanded="true"] {
          margin-left: 10px;
          &::before {
            display: none;
          }
          .icon_arrow_down {
            transform: rotate(180deg);
          }
          & + .common_tab_list {
            overflow: visible;
            margin-bottom: -8px;
            white-space: normal;
            .common_tab_item {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
  &.type_dot {
    .common_tab_list {
      margin: -2px 0 0 -2px;
    }
    .common_tab_item {
      margin: 2px 0 0 2px;
    }
    .common_tab_btn {
      position: relative;
      padding: 9px 10px;
      min-height: 40px;
      font-size: 14px;
      line-height: 22px;
      color: $color-gray;
      &[aria-selected="true"] {
        font-weight: 500;
        color: $color-black;
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: $color-black;
          transform: translateY(-50%);
          content: '';
        }
      }
      &:hover,
      &:focus {
        font-weight: 500;
        color: $color-black;
      }
    }
  }
  &.type_title {
    .common_tab_list {
      margin-left: -13px;
    }
    .common_tab_item {
      position: relative;
      & + .common_tab_item {
        &::before {
          position: absolute;
          top: 50%;
          width: 1px;
          height: 22px;
          background: map-get($color-line, line-02);
          transform: translateY(-50%);
          content: '';
        }
      }
    }
    .common_tab_btn {
      padding: 4px 13px;
      min-height: 40px;
      font-size: 26px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.05em;
      color: map-get($color-type, gray-02);
      &[aria-selected="true"] {
        color: $color-black;
        &:hover,
        &:focus {
          color: $color-black;
        }
      }
      &:hover,
      &:focus {
        color: $color-gray;
      }
    }
  }
  &.type_underline {
    .common_tab_list {
      margin: -2px 0 0 -2px;
    }
    .common_tab_item {
      margin: 2px 0 0 2px;
    }
    .common_tab_btn {
      position: relative;
      padding: 7px 10px;
      min-height: 40px;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: map-get($color-type, gray-01);
      &[aria-selected="true"] {
        color: $color-black;
        &::before {
          position: absolute;
          bottom: 4px;
          left: 10px;
          right: 10px;
          height: 2px;
          background: $color-black;
          content: '';
        }
      }
      &:disabled {
        color: map-get($color-type, gray-02);
        &:hover,
        &:focus {
          color: map-get($color-type, gray-02);
        }
      }
      &:hover,
      &:focus {
        color: $color-black;
      }
    }
  }
  &.type_linebox {
    border-bottom: 1px solid $color-black;
    .common_tab_list {
      display: flex;
    }
    .common_tab_item {
      position: relative;
      flex: 1;
      text-align: center;
      &:last-child {
        .common_tab_btn {
          border-right: 1px solid map-get($color-line, line-01);
          &[aria-selected="true"] {
            border-right-color: $color-black;
          }
        }
      }

      &.is-active {
        &::before {
          position: absolute;
          bottom: -1px;
          left: 1px;
          right: 1px;
          height: 1px;
          background: $color-white;
          content: '';
        }
      }
    }
    .bubble {
      position: absolute;
      top: -9px;
      left: 50%;
      transform: translateX(-50%);
      padding: 3px 6px 4px;
      border-radius: 16px;
      background-color: $color-primary;
      font-size: 12px;
      line-height: 12px;
      color: $color-white;
      z-index: 1;
      &::before {
        position: absolute;
        bottom: -8px;
        right: 50%;
        margin-right: -3px;
        border-style: solid;
        border-color: $color-primary transparent transparent transparent;
        border-width: 4px 3px;
        content: '';
      }
      &::after {
        display: block;
        width: 35px;
        height: 12px;
        background: svg-load('../../img/svg/icons/event_text.svg') center no-repeat;
        content: '';
      }
    }
    .common_tab_btn {
      display: block;
      position: relative;
      width: 100%;
      padding: 12px 10px 13px;
      border-top: 1px solid map-get($color-line, line-01);
      border-left: 1px solid map-get($color-line, line-01);
      font-size: 16px;
      line-height: 1.5;
      color: $color-gray;
      box-sizing: border-box;
      >span {
        display: block;
        overflow: hidden;
        margin: 0 auto;
        max-width: 144px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &[aria-selected="true"] {
        border-color: $color-black;
        border-right: 1px solid $color-black;
        border-bottom: 0;
        font-weight: 700;
        color: $color-black;
      }
      &:hover,
      &:focus {
        font-weight: 700;
        color: $color-black;
      }
      &:disabled {
        font-weight: 400;
        color: map-get($color-type, gray-02);
        &:hover,
        &:focus {
          color: map-get($color-type, gray-02);
        }
      }
    }
  }
}
.common_tab_list {
  font-size: 0;
}
.common_tab_item {
  display: inline-block;
}
.common_tab_btn {
  outline: none;
}