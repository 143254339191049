// main
.section_heading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 32px;
  .section_title_wrap {
    flex: 1;
    display: flex;
    align-items: flex-end;
    .section_title {
      flex: none;
    }
    .popup_btn_wrap {
      margin-left: 10px;
    }
    .common_popup_content {
      min-height: auto;
    }
  }
  .section_title {
    flex: 1;
    font-size: 26px;
    font-weight: 500;
    line-height: 26px;
    color: $color-black;
    .text_primary {
      color: $color-primary;
      line-height: 1;
      &.text_number {
        @include font-family('Roboto');
        font-weight: 500;
      }
    }
    & + .common_tab.type_dot {
      margin: -9px 0;
    }
  }
  .section_desc {
    margin-left: 10px;
    font-size: 13px;
    line-height: 15px;
    color: $color-gray;
  }
}
.page_main,
.page_main_sport,
.page_main_trip {
  .section_heading {
    margin-bottom: 34px;
  }
  .section_title {
    font-size: 32px;
    line-height: 32px;
    letter-spacing: -0.05em;
  }
  .common_tab.type_title {
    .common_tab_btn {
      font-size: 32px;
      line-height: 32px;
      letter-spacing: -0.05em;
    }
  }
}
.section_hero_banner {
  width: auto;
}
.section_promotion_banner {
  position: relative;
  margin-top: -27px;
  z-index: 1;
}
.section_open_notice {
  margin-top: 80px;
  + .section_banner_middle {
    margin-top: 24px;
  }
  .product_period {
    @include font-family('Notosans');
    color: $color-primary;
  }
}
.section_ranking_main {
  margin-top: 106px;
  .section_heading {
    margin-bottom: 30px;
  }
}
.section_today_recommend {
  margin-top: 118px;
  width: auto;
  .section_heading {
    width: $content-width;
    margin: 0 auto 34px;
  }
}
.today_recommend {
  position: relative;
  height: 466px;
}
.today_recommend_list {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 336px;
  margin: 16px -560px 0 0;
  transform: translateY(-50%);
  z-index: 2;
}
.today_recommend_item {
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: $color-white;
    opacity: 0.15;
    content: '';
  }
  &.is-active {
    &::before {
      top: -2px;
      height: 3px;
      opacity: 1;
    }
  }
}
.today_recommend_btn {
  width: 336px;
  padding: 17px 0 28px;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  color: $color-white;
  .text {
    @include ellipsis;
  }
}
.today_recommend_wrap {
  position: relative;
  &::before {
    position: absolute;
    bottom: -28px;
    width: 100%;
    height: 28px;
    background: $color-white;
    content: '';
  }
  .common_inner {
    position: relative;
    height: 466px;
  }
}
.today_recommend_link {
  display: flex;
  align-items: center;
  position: relative;
  width: 740px;
  height: 100%;
}
.today_recommend_txtbox {
  color: $color-white;
  width: 309px;
}
.today_recommend_title {
  @include multi-ellipsis(2);
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.05em;
}
.today_recommend_subtit {
  @include multi-ellipsis(2);
  margin-top: 16px;
  font-size: 18px;
  line-height: 26px;
}
.today_recommend_imgbox {
  overflow: hidden;
  position: absolute;
  top: 50px;
  left: 380px;
  width: 360px;
  height: 444px;
  border-radius: 10px;
  background: url(../img/svg/common/img_default.svg) center / 54.5% no-repeat map-get($color-background, bg-01);
  @include img-border;
  &::before {
    opacity: 0.02;
  }
}
.today_recommend_img {
  width: 100%;
  height: 100%;
}
.section_payco_vip {
  margin-top: 154px;
  .logo_payco_vip {
    margin-bottom: 2px;
  }
}
.section_discount_exhibit {
  margin: 110px auto 120px;
  .section_heading {
    height: 38px;
  }
  .exhibit_banner {
    overflow: hidden;
    height: 152px;
    border-radius: 12px;
    &_link {
      display: block;
      height: 100%;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.section_ticket_spot {
  margin-top: 114px;
  width: auto;
  background: map-get($color-background, bg-01);
  .common_inner {
    position: relative;
    height: 400px;
  }
  .section_heading {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 2;
    .section_title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: $color-primary;
    }
  }
}
.ticket_spot {
  height: 100%;
  .swiper-slide {
    background: map-get($color-background, bg-01);
  }
  .common_nav_box {
    position: absolute;
    bottom: 60px;
    left: 0;
    z-index: 1;
  }
}
.ticket_spot_video {
  overflow: hidden;
  position: absolute;
  top: 47px;
  right: 0;
  width: 545px;
  height: 306px;
  border-radius: 12px;
}
.ticket_spot_link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: absolute;
  top: 114px;
  left: 0;
  width: 500px;
  height: 130px;
  &:hover,
  &:focus {
    .ticket_spot_title {
      text-decoration: underline;
    }
  }
}
.ticket_spot_title {
  @include multi-ellipsis(2);
  max-height: 132px;
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.05em;
  color: $color-black;
}
.section_weekly_culture {
  margin-top: 120px;
  .product_grid_unit {
    .product_period {
      @include ellipsis;
      @include font-family('Notosans');
      font-weight: 400;
    }
  }
}
.weekly_culture {
  display: flex;
  position: relative;
}
.weekly_culture_highlight {
  width: 430px;
  margin-right: 45px;
  .product_grid_unit {
    position: sticky;
    top: 0;
    left: 0;
    .product_imgbox {
      width: 430px;
      min-height: 430px;
      height: auto;
    }
  }
}
.weekly_culture_col {
  &:last-child {
    margin-top: 85px;
    .product_grid_item {
      margin-right: 0;
    }
  }
  .product_grid_item {
    width: 300px;
    margin-right: 45px;
  }
  .product_grid_unit {
    .product_imgbox {
      width: 300px;
      min-height: 300px;
      height: auto;
    }
    .product_img {
      min-height: 300px;
    }
  }
}
.section_best_culture {
  margin-top: 120px;
}
.section_concert_hall {
  margin-top: 120px;
  .section_heading {
    align-items: center;
    &.is-expand {
      align-items: flex-start;
    }
    .common_tab.type_expand {
      max-width: 880px;
    }
  }
}
.main_concert_hall {
  overflow: hidden;
  position: relative;
  height: 332px;
  border-radius: 16px;
  background: map-get($color-background, bg-01);
  .concert_hall_img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  .concert_hall_link {
    position: absolute;
    top: 0;
    left: 0;
    width: 315px;
    height: 100%;
  }
  .product_grid {
    position: absolute;
    top: 50px;
    left: 345px;
  }
  .product_grid_list {
    margin-top: -24px;
  }
  .product_grid_item {
    margin: 24px 30px 0 0;
    width: 315px;
  }
  .product_grid_unit {
    .product_link {
      display: flex;
      align-items: center;
    }
    .product_imgbox {
      flex: none;
      margin-right: 15px;
      width: 84px;
      height: 104px;
    }
    .product_info {
      margin-top: 0;
    }
    .product_title {
      @include multi-ellipsis(1);
    }
  }
}

// sport
.section_sport_open {
  margin-top: 70px;
}
.section_sports_thumb {
  margin-top: 85px;
}
.sport_open_filter {
  position: relative;
  .common_tab.type_capsule {
    margin-bottom: 24px;
  }
  .common_nav_box {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.sport_match_schedule {
  overflow: hidden;
  width: 1148px;
  margin-left: -14px;
  .match_card_swiper {
    width: $content-width;
    margin-left: 14px;
  }
}

.sport_quick_reserve {
  position: relative;
  margin-bottom: 60px;
  border: 1px solid map-get($color-type, gray-01);
  border-radius: 10px;
  box-shadow: 0px 17px 21px rgba(0, 0, 0, 0.04);
}
.quick_reserve_choice {
  display: flex;
}
.quick_reserve_section {
  flex: 1;
  & + & {
    border-left: 1px solid map-get($color-line, line-01);
  }
}
.quick_reserve_heading {
  padding: 20px 20px 8px;
  font-size: 0;
  color: $color-black;
}
.quick_reserve_step {
  margin-right: 8px;
  font-size: 16px;
  line-height: 24px;
  color: $color-primary;
}
.quick_reserve_title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.quick_reserve_select {
  @include scrollbar;
  padding: 6px 20px 0;
  height: 186px;
}
.quick_reserve_item {
  & + & {
    margin-top: 2px;
  }
}
.quick_reserve_btn {
  padding: 7px 13px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 6px;
  text-align: left;
  color: $color-black;
  &[aria-selected="true"] {
    font-weight: 500;
    border-color: $color-black;
  }
}
.sport_quick_util {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px 17px;

  border-top: 1px solid map-get($color-line, line-01);
  .common_btn {
    width: 333px;
  }
}
.sport_quick_notice {
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  line-height: 18px;
  color: $color-gray;
  .icon_info {
    margin-right: 4px;
  }
}
.sport_quick_text {
  vertical-align: middle;
}
.section_sport_sns {
  margin-top: 80px;
}
.sport_sns {
  display: flex;
  border-radius: 10px;
  background: map-get($color-background, bg-01);
}
.sport_sns_heading {
  flex: none;
  width: 382px;
  padding: 87px 50px;
  font-size: 0;
  box-sizing: border-box;
  .icon_facebook {
    margin-right: 16px;
    width: 48px;
    height: 48px;
  }
}
.sport_sns_title {
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: $color-black;
  vertical-align: middle;
}
.sport_sns_list {
  margin-top: -30px;
  padding: 40px 0;
  font-size: 0;
}
.sport_sns_item {
  display: inline-block;
  box-sizing: border-box;
  margin-top: 30px;
  & + & {
    border-left: 1px solid map-get($color-line, line-01);
  }
  &:nth-child(3n + 1) {
    border: 0;
  }
}
.sport_sns_link {
  display: block;
  padding: 0 7px 0 10px;
}
.sport_sns_img {
  margin-right: 12px;
  width: 60px;
  height: 60px;
  vertical-align: middle;
}
.sport_sns_team {
  @include ellipsis;
  display: inline-block;
  width: 134px;
  font-size: 18px;
  line-height: 26px;
  color: $color-black;
  vertical-align: middle;
}
// 스포츠 e-sports 배너영역
.section_sports_thumb {
  .sport_match_schedule {
    width:1120px;
    margin:0 auto;
  }
  .e_sports_type04 {
    .thumb_card {
      width: 259px;
      height: 134px;
    }
    .match_card_visual {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
.section_sports_banner {
  margin-top: 85px;
  [class*="e_sports_type"] {
    display: flex;
    margin: 0 auto;
    gap: 0 24px;
    width: 1120px;
    height: 134px;
    .thumb_card {
      flex: 1;
      width: 100%;
      height: 134px;
    }
    .match_card_visual {
      width: 100%;
      height: 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

// trip
.page_main_trip {
  .product_grid_unit {
    .product_price {
      margin-top: 14px;
      line-height: 20px;
      text-align: left;
      .product_price_number {
        @include font-family('Roboto');
        font-size: 22px;
        font-weight: 700;
      }
      .product_price_text {
        margin-left: -2px;
        font-size: 14px;
        &::after {
          margin-left: 4px;
          font-size: 22px;
          content: '-';
        }
      }
    }    
  }
}

.section_trip_recommend {
  margin-top: 80px;
  margin-bottom: 50px;
  .product_grid_unit {
    .product_imgbox {
      height: 259px;
    }
    .product_flag {
      position: absolute;
      top: 12px;
      right: 10px;
      margin-top: 0;
    }
  }
}

.section_trip_pick {
  position: relative;
  margin-top: 80px;
  .section_heading {
    margin-bottom: 0;
  }
  .common_nav_box {
    position: absolute;
    top: 10px;
    right: 0;
  }
  .product_grid_item {
    width: 467px;
    &:last-child {
      margin-right: 0;
    }
  }
  .product_grid_unit {
    .product_imgbox {
      width: 467px;
      height: 349px;
    }
    .product_info {
      margin-top: 30px;
    }
    .product_depart {
      display: block;
      margin-bottom: 6px;
      color: $color-primary;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
.trip_pick_filter {
  margin-bottom: 40px;
  text-align: center;
  .filter_place,
  .filter_type {
    display: inline-block;
  }
  .filter_txt {
    font-size: 38px;
    vertical-align: top;
    margin-left: 12px;
  }
  .common_dropdown {
    top:10px;
    .dropdown_expand_btn[aria-expanded="true"] {
      border-bottom: transparent;
    }
    .dropdown_expand_btn {
      position: relative;
      margin-left: 8px;
      padding: 0 28px 6px 4px;
      font-size: 38px;
      font-weight: 700;
      border-bottom: 2px solid map-get($color-type, black-01);
      border-radius: 0;
      background-color: transparent;
      text-align: center;
      &::before{
        border-radius: 0;
        border-color: transparent;
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 2px;
        width: 22px;
        height: 26px;
        background-image: svg-load("../../img/svg/icons/arrow_down_22.svg");
        transform: translateY(-50%);
        content: '';
        border-top-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }
  .common_dropdown_list{
    border-radius: 6px;
    top: 48px;
    left: 7px;
    border-top: 1px solid #242428;
  }
  .filter_type{
    .common_dropdown{
      min-width: 143px;
    }
  }
}

.section_trip_video {
  margin-top: 118px;
  width: auto;
  .section_heading {
    width: $content-width;
    margin: 0 auto 34px;
  }
}
.trip_video {
  position: relative;
}
.trip_video_list {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 526px;
  margin-right: -560px;
  transform: translateY(-50%);
  z-index: 2;
}
.trip_video_item {
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: $color-black;
    opacity: 0.15;
    content: '';
  }
  &.is-active {
    &::before {
      top: -2px;
      height: 3px;
      opacity: 1;
    }
    .trip_video_btn {
      .text {
        font-weight: 500;
      }
    }
  }
  &:last-child {
    .trip_video_btn {
      padding-bottom: 0;
    }
  }
}
.trip_video_btn {
  width: 336px;
  padding: 17px 0 28px;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  color: $color-black;
  .text {
    @include ellipsis;
  }
}
.trip_video_wrap {
  .common_inner {
    position: relative;
    display: flex;
    align-items: center;
    height: 400px;
  }
}
.trip_video_link {
  display: flex;
  align-items: center;
  position: relative;
  width: 740px;
  height: 100%;
}
.trip_video_box {
  width: 545px;
  height: 306px;
  iframe {
    border-radius: 10px;
  }
}