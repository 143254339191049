.match_card {
  overflow: hidden;
  position: relative;
  margin-bottom: 60px;
  width: 353px;
  height: 330px;
  border: 1px solid map-get($color-line, line-01);
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 17px 21px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
}
.match_card_visual {
  position: relative;
  height: 163px;
  .match_thmbnail {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 263px;
    height: 82px;
    transform: translate(-50%, -50%);
  }
  .match_team_group {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .match_team_imgbox {
    width: 76px;
    height: 76px;
  }
  .match_team_img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  .icon_versus {
    width: 40px;
    height: 40px;
    margin: 0 20px;
  }
}
.match_card_info {
  position: relative;
  padding: 16px 20px 11px 20px;
  color: $color-black;
  &::before {
    position: absolute;
    top: 0;
    left: 17px;
    right: 17px;
    height: 1px;
    background: map-get($color-line, line-01);
    content: '';
  }
}
.match_card_date {
  display: block;
  font-size: 18px;
  line-height: 26px;
}
.match_card_place {
  @include ellipsis;
  margin-top: 3px;
  font-size: 15px;
  line-height: 22px;
  color: $color-gray;
}
.match_card_btnarea {
  padding: 20px;
}
.match_card_empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .icon_schedule_empty {
    width: 100px;
    height: 100px;
  }
  .match_card_text {
    display: block;
    font-size: 15px;
    line-height: 22px;
    color: $color-gray;
  }
}
.thumb_card {
  width: 100%;
  height: 134px;
  margin-bottom: 0;
  .match_card_visual{
    position:static;
    height:100%;
   .match_thmbnail{
    position: static;
    transform: none;
    height:100%
   }
    img{
      width:100%;
    }
  }
}

