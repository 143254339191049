.common_dropdown {
  display: inline-block;
  position: relative;
  min-width: 154px;
  max-width: 100%;
  color: $color-black;
}
.dropdown_btn_wrap {
  overflow: hidden;
}
.dropdown_expand_btn {
  @include ellipsis;
  position: relative;
  padding: 10px 34px 10px 10px;
  width: 100%;
  height: 42px;
  border-radius: 6px;
  background: $color-white;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: $color-black;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid map-get($color-type, gray-03);
    border-radius: 6px;
    box-sizing: border-box;
    content: '';
  }
  &::after {
    position: absolute;
    top: 50%;
    right: 13px;
    margin-top: -2px;
    border-style: solid;
    border-color: transparent;
    border-top-color: currentColor;
    border-width: 4.5px 3.5px;
    content: '';
  }
  &[aria-expanded="true"] {
    border-radius: 6px 6px 0 0;
    &::before {
      border-radius: 6px 6px 0 0;
      border-color: $color-black;
    }
    &::after {
      margin-top: -8px;
      border-top-color: transparent;
      border-bottom-color: currentColor;
    }
    & + .common_dropdown_list {
      display: block;
    }
  }
  &:disabled {
    color: map-get($color-type, gray-02);
  }
}
.common_dropdown_list {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  @include scrollbar;
  max-height: 211px;
  border: 1px solid $color-black;
  border-top: 0;
  border-radius: 0 0 6px 6px;
  background: $color-white;
  box-sizing: border-box;
  z-index: map-get($z-index, nav);
}
.common_dropdown_btn {
  @include ellipsis;
  padding: 10px 9px;
  width: 100%;
  height: 42px;
  background: $color-white;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: $color-black;
  &[aria-selected="true"] {
    background: map-get($color-background, bg-02);
    font-weight: 500;
  }
}