.common_footer {
  border-top: 1px solid $color-black;
}
.footer_menu {
  border-bottom: 1px solid map-get($color-line, line-01);
}
.footer_menu_list {
  margin: 0 auto;
  width: $content-width;
  font-size: 0;
}
.footer_menu_item {
  display: inline-block;
  position: relative;
  padding: 16px 20px;
  & + & {
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 1px;
      height: 12px;
      background: map-get($color-line, line-02);
      transform: translateY(-50%);
      content: '';
    }
  }
  &:first-child {
    padding-left: 0;
  }
}
.footer_menu_link {
  display: block;
  font-size: 13px;
  line-height: 18px;
  color: $color-black;
  .text_bold {
    font-weight: 700;
  }
}
.footer_inner {
  position: relative;
  margin: 0 auto;
  width: $content-width;
  padding: 26px 0 85px;
  font-size: 12px;
  line-height: 16px;
  color: $color-gray;

}
.footer_corp_title {
  font-weight: 700;
}
.footer_address {
  margin-top: 10px;
  font-size: 0;
}
.footer_address_item {
  display: inline-block;
  position: relative;
  padding: 2px 8px;
  font-size: 12px;
  line-height: 16px;
  & + & {
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 1px;
      height: 10px;
      background: map-get($color-type, gray-01);
      transform: translateY(-50%);
      content: '';
    }
  }
  &:first-child {
    padding-left: 0;
  }
  .btn_hyperlink {
    margin-left: 2px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    &::before {
      display: none;
    }
  }
}
.footer_copyright {
  display: block;
  margin-top: 15px;
  letter-spacing: -0.04em;
  color: map-get($color-type, gray-02);
}
.footer_etc {
  position: absolute;
  top: 96px;
  right: 0;
}
.footer_sns {
  margin-top: 0px;
  font-size: 0;
}
.footer_sns_item {
  display: inline-block;
  & + & {
    margin-left: 16px;
  }
}
.footer_sns_link {
  display: block;
}