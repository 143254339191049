// product detail
.section_product_info {
  display: flex;
  position: relative;
  margin-top: 64px;
  padding-bottom: 40px;
}
.product_detail_imgbox {
  position: relative;
  overflow: hidden;
  flex: none;
  margin-right: 40px;
  width: 405px;
  height: 500px;
  border-radius: 10px;
  background: map-get($color-background, bg-01);
  @include img-border;
  .product_detail_img {
    width: 100%;
    height: 100%;
  }
}
.product_detail_info {
  flex: 1;
  .flag_area {
    margin-bottom: 17px; 
  }
  .product_heading {
    position: relative;
    padding: 0 75px 10px 0;
    border-bottom: 1px solid $color-black;
    line-height: 0;
    color: $color-black;
    .popup_btn_wrap {
      position: absolute;
      padding: 10px;
      bottom: 0;
      right: -10px;
      .common_popup_wrap {
        left: auto;
        right: 10px;
        margin-top: 10px;
      }
    }
  }
  .product_title {
    background: none;
    font-size: 26px;
    font-weight: 500;
    line-height: 34px;
    color: $color-black;
  }
  .product_sub_title {
    margin-top: 5px;
    font-size: 14px;
    line-height: 22px;
  }
  .product_info {
    .common_popup_content {
      min-height: 238px;
    }
  }
  .product_info_list {
    padding: 14px 0 20px;
    & + .product_info_list {
      border-top: 1px solid map-get($color-line, line-01);
    }
    &.type_col2 {
      display: flex;
      flex-wrap: wrap;
      .product_info_item {
        width: 50%;
        &:nth-child(2n+1) {
          padding-right: 10px;
        }
      }
    }
  }
  .product_info_item {
    display: flex;
    padding: 7px 0 6px;
    font-size: 15px;
    line-height: 22px;
    box-sizing: border-box;
    .text_emphasis {
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }
  }
  .product_info_title {
    flex: none;
    width: 70px;
    margin-right: 10px;
    color: $color-gray;
  }
  .product_info_desc {
    flex: 1;
    color: $color-black;
    .popup_btn_wrap {
      margin-top: 8px;
      .common_popup_wrap {
        margin-top: 2px;
      }
    }
  }
  .product_info_subitem {
    line-height: 24px;
    & + .product_info_subitem {
      margin-top: 5px;
    }
    .text_emphasis {
      margin-left: 3px;
    }
  }
}
.product_detail_option {
  position: absolute;
  bottom: 10px;
  right: 0;
  font-size: 0;
  .popup_btn_wrap {
    display: inline-block;
    & + .popup_btn_wrap {
      margin-left: 4px;
    }
    .common_popup_wrap {
      left: auto;
      right: 0;
      margin-top: 10px;
    }
  }
}
.product_option_btn {
  position: relative;
  padding: 5px 25px 5px 9px;
  border: 1px solid map-get($color-line, line-04);
  border-radius: 6px;
  font-size: 13px;
  line-height: 18px;
  color: $color-black;
  background-color: $color-white;
  &::after {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -2px;
    border-style: solid;
    border-color: transparent;
    border-top-color: currentColor;
    border-width: 4.5px 3.5px;
    content: '';
  }
  &.type_clean {
    border-color: map-get($color-type, clean-01);
    color: map-get($color-type, clean-01);
  }
  &.type_waiting {
    border-color: map-get($color-type, waiting-01);
    color: map-get($color-type, waiting-01);
  }
  &.type_coupon {
    border-color: $color-black;
    background: $color-black;
    color: $color-white;
    &::after {
      display: none;
    }
    .common_icon {
      position: absolute;
      top: 50%;
      right: 7px;
      margin-top: -8px;
    }
  }
  &.type_device {
    border-color: #386DBE;
    color: #386DBE;
  }
}
.section_product_reserve {
  position: relative;
  & + .section_banner_middle {
    margin-top: 60px;
  }
}
.product_detail_reserve {
  display: flex;
  max-height: 305px;
  border: 1px solid map-get($color-type, gray-01);
  border-radius: 10px;
  box-sizing: border-box;
}
.product_reserve_heading {
  margin-bottom: 18px;
  width: 100px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: $color-black;
}
.product_reserve_step {
  display: block;
  margin-bottom: 4px;
  color: $color-primary;
}
.product_reserve_title {
  font-weight: 700;
}
.product_reserve_date {
  display: flex;
  padding: 20px 24px 13px 23px;
  .react-datepicker__month {
    width: 298px;
  }
}
.product_reserve_time {
  display: flex;
  align-items: flex-start;
  padding: 20px 0 20px 24px;
  border-left: 1px solid map-get($color-line, line-01);
}
.product_time_list {
  @include scrollbar;
  padding-right: 24px;
  width: 257px;
  height: 100%;
}
.product_time_item {
  & + & {
    margin-top: 8px;
  }
}
.product_time_btn {
  padding: 11px 18px 11px 15px;
  width: 100%;
  border: 1px solid map-get($color-line, line-04);
  border-radius: 6px;
  text-align: left;
  &[aria-selected="true"] {
    border-color: $color-black;
  }
}
.product_time {
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: $color-black;
}
.product_time_cast {
  display: flex;
  margin-top: 4px;
  font-size: 13px;
  line-height: 18px;
}
.product_time_title {
  flex: none;
  margin: 1px 5px 0 0;
  color: $color-gray;
}
.product_time_actor {
  color: $color-black;
}
.product_time_notice {
  margin-right: 24px;
  padding: 11px 18px 11px 15px;
  width: 257px;
  border: 1px solid map-get($color-line, line-04);
  border-radius: 6px;
  text-align: left;
  color: $color-gray;
  box-sizing: border-box;
}
.product_reserve_seat {
  flex: 1;
  padding: 19px 0 20px 20px;
  border-left: 1px solid map-get($color-line, line-01);
  border-radius: 0 10px 10px 0;
  background: map-get($color-background, bg-02);
  .product_reserve_heading {
    width: auto;
  }
  .product_reserve_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
}
.product_seat_list {
  @include scrollbar;
  padding-right: 24px;
  max-height: 222px;
}
.product_seat_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  line-height: 18px;
  & + & {
    margin-top: 8px;
  }
}
.product_seat_title {
  color: $color-gray;
}
.product_seat_remain {
  display: inline-flex;
  color: $color-black;
}
.product_seat_number {
  font-weight: 700;
}
.product_seat_text {
  margin-left: 1px;
}
.product_seat_notice {
  font-size: 13px;
  line-height: 18px;
  color: $color-gray;
  & + & {
    margin-top: 10px;
  }
}
.product_reserve_notice {
  flex: 1;
  padding: 30px;
  font-size: 16px;
  line-height: 24px;
  color: $color-black;
  text-align: center;
}
.product_reserve_util {
  display: flex;
  margin-top: 19px;
}
.product_reserve_banner {
  display: inline-block;
  margin-right: 12px;
  width: 147px;
  height: 30px;
  vertical-align: top;
}
.product_reservebn_link {
  display: block;
  height: 100%;
}
.product_reservebn_img {
  width: 100%;
  height: 100%;
}
.product_reserve_btnarea {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 0;
  .common_btn {
    vertical-align: middle;
  }
  .common_btn + .common_btn {
    margin-left: 6px;
  }
  .btn_ghost {
    width: 252px;
    font-weight: 500;
    > span {
      font-size: 16px;
      line-height: 21px;
    }
  }
  .btn_primary {
    width: 270px;
  }
}
.product_reserve_arrow {
  display: inline-block;
  margin: 0 20px;
  padding: 7px;
  width: 40px;
  height: 40px;
  border: 1px solid map-get($color-line, line-01);
  border-radius: 50%;
  vertical-align: middle;
  box-sizing: border-box;
}
.section_product_casting {
  margin-top: 40px;
}
.product_detail_casting {  
  padding-top: 30px;
  border-top: 1px solid map-get($color-line, line-01);
  font-size: 0;
}
.product_casting_heading {
  .common_btn {
    margin-left: 9px;
    vertical-align: middle;
  }
}
.product_casting_title {
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: $color-black;
  vertical-align: middle;
}
.product_casting {
  position: relative;
  margin: 15px 88px 0;
  vertical-align: top;
  .product_casting_nav {
    position: absolute;
    top: 36px;
    padding: 10px;
    &.swiper-button-prev {
      left: -44px;
    }
    &.swiper-button-next {
      right: -44px;
    }
  }
  &.type_less {
    .product_casting_list {
      justify-content: center;
      .product_casting_item {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.product_casting_swiper {
  overflow: hidden;
  padding: 20px 20px 0;
}
.product_casting_list {
  display: flex;
  width: 100%;
  height: 100%;
}
.product_casting_item {
  width: 78px;
  margin-right: 40px;
}
.product_casting_link {
  display: block;
  text-align: center;
  outline: none;
  &:hover,
  &:focus {
    .product_casting_imgbox {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: svg-load('../../img/svg/icons/check.svg', fill=$color-white) center / 24px no-repeat rgba($color: $color-black, $alpha: 0.5);
        content: '';
        z-index: 1;
      }
      &::after {
        position: absolute;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        border: 2px solid $color-black;
        border-radius: 50%;
        content: '';
        z-index: 1;
      }
    }
  }
}
.product_casting_link.disabled {
  cursor: default;
  &:hover,
  &:focus {
    .product_casting_imgbox {
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
}
.product_casting_imgbox {
  position: relative;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background: svg-load('../../img/svg/icons/profile.svg') center / 46px no-repeat map-get($color-background, bg-01);
}
.product_casting_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.product_casting_name {
  display: block;
  margin-top: 9px;
  font-size: 15px;
  line-height: 22px;
  color: $color-black;
}
.product_casting_role {
  @include multi-ellipsis(2);
  font-size: 13px;
  line-height: 18px;
  color: map-get($color-type, gray-01);
}
.section_product_tab {
  position: sticky;
  top: 124px;
  margin-top: 40px;
  width: 100%;
  background: $color-white;
  z-index: map-get($z-index, nav-fixed)
}
.product_detail_tab {
  margin: 0 auto;
  width: $content-width;
  padding-top: 9px;  
}
.product_detail_tabcontent {
  img {
    max-width: 100%;
  }
}
.section_product_tabcont {
  margin-top: 40px;
  padding-top: 40px;
  &:first-child {
    margin-top: 0;
  }
}
.product_content_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 0 32px;
  padding-bottom: 11px;
  border-bottom: 1px solid $color-black;
  &:first-child {
    margin-top: 0;
  }
}
.product_content_title {
  display: inline-flex;
  align-items: baseline;
  flex: 1;
  font-size: 26px;
  font-weight: 500;
  line-height: 34px;
  color: $color-black;
  .text_primary {
    margin-left: 4px;
    color: $color-primary;
  }
  .text_number {
    @include font-family('Roboto');
  }
}
.product_content_subtit {
  margin: 40px 0 12px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: $color-black;
  &:first-of-type {
    margin-top: 0;
  }
}
.product_editor {
  font-size: 15px;
  line-height: 22px;
  color: $color-gray;
}
.product_content_desc {
  font-size: 15px;
  line-height: 22px;
  color: $color-gray;
  & + & {
    margin-top: 12px;
  }
  .text_primary {
    color: $color-primary;
  }
  .text_black {
    color: $color-black;
  }
}
.product_banner {
  .section_product_tabcont & {
    margin-bottom: 12px;
  }
  .product_banner_link {
    display: block;
  }
  .product_banner_img {
    width: 100%;
    vertical-align: top;
  }
}
.product_templete {
  & ~ .product_content_subtit {
    margin-top: 50px;
  }
}
.product_additional_info {
  margin: 20px 0 10px;
  font-size: 16px;
  line-height: 24px;
  color: $color-black;
  &:first-child {
    margin-top: 0;
  }
}
.product_additional_desc {
  color: $color-gray;
}
.product_additional_list {
  font-size: 0;
  margin: -10px 0 0 -10px;
}
.product_additional_item {
  display: inline-block;
  margin: 10px 0 0 10px;
  width: 367px;
  &:nth-child(3n+1) {
    margin-left: 9px;
  }
}
.product_additional_imgbox {
  position: relative;
  padding-top: 100%;
  background-color: map-get($color-background, bg-01);
}
.product_additional_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.product_additional_notice {
  color: $color-primary;
}
.product_video {
  position: relative;
  height: 637px;
  background-color: map-get($color-background, bg-01);
  & + & {
    margin-top: 12px;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.product_info_table {
  border-top: 1px solid map-get($color-line, line-02);
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  table {
    width: 100%;
    color: $color-black;
  }
  th,
  td {
    padding: 14px 15px;
    border-bottom: 1px solid map-get($color-line, line-01);
  }
  th {
    background: map-get($color-background, bg-02);
    font-weight: 400;
  }
}
.product_star14 {
  position: relative;
  width: 82px;
  height: 14px;
  &::before, 
  .product_star14_per {
    width: 100%;
    height: 100%;
    background: svg-load('../../img/svg/icons/star_14_group.svg') no-repeat;
  }
  &::before {
    display: block;
    @include color-filter(map-get($color-background, bg-03));
    content: '';
  }
  .product_star14_per {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.product_star24 {
  position: relative;
  width: 136px;
  height: 24px;
  &::before, 
  .product_star24_per {
    width: 100%;
    height: 100%;
    background: svg-load('../../img/svg/icons/star_24_group.svg') no-repeat;
  }
  &::before {
    display: block;
    @include color-filter(map-get($color-background, bg-03));
    content: '';
  }
  .product_star24_per {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.product_star_rate {
  display: flex;
  align-items: center;
  margin-top: 5px;
  .product_star24 {
    margin-bottom: 7px;
  }
}
.product_star_score {
  margin-left: 10px;
  @include font-family('Roboto');
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: $color-black;
  .product_star_current {
    font-weight: 500;
    line-height: 24px;
  }
}
.product_review_templete {
  &.type_image {
    .review_event_box {
      padding: 20px;
      border: 0;
      border-radius: 0;
    }
  }
  .review_event_box {
    border: 1px solid map-get($color-line, line-02);
    border-radius: 10px;
    background: map-get($color-background, bg-01);
    box-sizing: border-box;
  }
  .review_event_heading {
    position: relative;
    padding: 17px 20px 14px 160px;
    border-bottom : 1px solid map-get($color-line, line-02);
  }
  .review_event_badge {
    position: absolute;
    top: 22px;
    left: 20px;
    padding: 5px 20px 7px;
    border-radius: 36px;
    background: $color-black;
    color: $color-white;
  }
  .review_event_title {
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: $color-black;
  }
  .review_event_desc {
    font-size: 14px;
    line-height: 22px;
    color: $color-gray;
  }
  .review_event_imgbox {
    width: 693px;
    margin: 0 auto;
  }
  .review_event_img {
    width: 100%;
  }
}
.review_event_content {
  padding: 18px 20px 16px;
  .review_info_item {
    display: flex;
    position: relative;
    padding-left: 6px;
    font-size: 14px;
    line-height: 22px;
    color: $color-black;
    &::before {
      position: absolute;
      top: 11px;
      left: 0;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: $color-black;
      content: '';
    }
  }
  .review_info_title {
    flex: none;
    width: 72px;
    margin-right: 10px;
    font-weight: 500;
  }
  .review_info_desc {
    margin-right: 11px;
    .text_number {
      @include font-family('Roboto');
      font-weight: 300;
    }
  }
  .review_event_notice {
    margin-top: 17px;
    padding-top: 12px;
    border-top: 1px solid map-get($color-line, line-01);
  }
  .review_notice_item {
    position: relative;
    padding-left: 8px;
    font-size: 13px;
    line-height: 18px;
    color: $color-gray;
    &::before {
      position: absolute;
      top: 8px;
      left: 0;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: $color-gray;
      content: '';
    }
    & + .review_notice_item {
      margin-top: 4px;
    }
  }
  .review_event_img {
    width: 100%;
    vertical-align: top;
  }
}
.product_comment_notice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0 12px;
  .comment_notice_desc {
    font-size: 14px;
    line-height: 22px;
    color: $color-gray;
  }
  .btn_hyperlink {
    font-size: 14px;
  }
}
.product_comment_form {
  border: 1px solid map-get($color-type, gray-03);
  border-radius: 10px;
  &.is-focus {
    border-color: $color-black;
  }
  .comment_content {
    padding: 15px 0 22px;
  }
  .comment_star_rate {
    margin-bottom: 18px;
    padding: 0 20px;
    font-size: 0;
  }
  .comment_star_select {
    display: inline-block;
    margin-left: -3px;
    font-size: 0;
    vertical-align: middle;
    &.is-active {
      .star_radio {
        &:checked ~ .star_label {
          @include color-filter(map-get($color-background, bg-03));
        }
        &:checked + .star_label {
          filter: none;
        }
      }
      .star_label {
        filter: none;
      }
    }
    .star_radio {
      @include blind;
    }
    .star_label {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-left: 3px;
      background: svg-load('../../img/svg/icons/star.svg') center / 100% no-repeat;
      @include color-filter(map-get($color-background, bg-03));
    }
  }
  .comment_star_desc {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: $color-black;
    vertical-align: middle;
  }
  .comment_input_box {
    position: relative;
  }
  .comment_textarea {
    @include scrollbar;
    width: 100%;
    padding: 0 30px 0 20px;
    height: 84px;
    border: 0;
    font-size: 14px;
    line-height: 22px;
    color: $color-black;
    vertical-align: top;
    box-sizing: border-box;
    resize: none;
    outline: none;
    caret-color: $color-primary;
    &::placeholder {
      color: map-get($color-type, gray-02);
    }
  }
  .comment_util {
    position: relative;
    min-height: 60px;
    border-top: 1px solid map-get($color-line, line-01);
  }
  .comment_util_privacy {
    font-size: 0;
    line-height: 0;
    color: $color-black;
    .checkbox_item {
      display: inline-block;
      padding: 19px 0 19px 18px;
      line-height: 22px;
      vertical-align: middle;
    }
  }
  .comment_privacy_btn {
    display: inline-block;
    position: relative;
    width: 22px;
    height: 22px;
    vertical-align: middle;
    &::after {
      position: absolute;
      top: 50%;
      left: 6px;
      margin-top: -2px;
      border-style: solid;
      border-color: transparent;
      border-top-color: currentColor;
      border-width: 4.5px 3.5px;
      content: '';
    }
    &[aria-expanded="true"] {
      &::after {
        margin-top: -7px;
        border-top-color: transparent;
        border-bottom-color: currentColor;
      }
      & + .comment_privacy_content {
        display: block;
      }
    }
  }
  .comment_privacy_content {
    display: none;
    padding: 16px 18px;
    background: map-get($color-background, bg-02);
    border-radius: 0 0 10px 10px;
  }
  .comment_privacy_list {
    counter-reset: number;
  }
  .comment_privacy_item {
    padding-left: 14px;
    text-indent: -14px;
    font-size: 14px;
    line-height: 22px;
    color: $color-gray;
    &::before {
      display: inline-block;
      padding-right: 4px;
      min-width: 14px;
      text-indent: 0;
      box-sizing: border-box;
      counter-increment: number;
      content: counter(number);
    }
    & + .comment_privacy_item {
      margin-top: 6px;
    }
  }
  .comment_util_right {
    display: flex;
    align-items: center;
    position: absolute;
    top: 12px;
    right: 19px;
  }
  .comment_length {
    display: inline-block;
    @include font-family('Roboto');
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
  }
  .current_length {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    color: $color-black;
  }
  .limit_length {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    color: map-get($color-type, gray-01);
  }
  .comment_btn_box {
    margin-left: 12px;
    .common_btn.btn_small {
      padding: 0 18px;
    }
  }
}
.product_comment_list {
  margin-top: 23px;
}
.product_comment_item {
  position: relative;
  border-bottom: 1px solid map-get($color-line, line-01);
  padding: 24px 100px 27px 0;
  &.is-modify {
    .product_comment_desc {
      color: map-get($color-type, gray-02);
    }
  }
  .product_star14 {
    margin-bottom: 10px;
  }
  .product_comment_desc {
    font-size: 15px;
    line-height: 22px;
    color: $color-black;
  }
  .product_comment_info {
    font-size: 0;
    margin-top: 14px;
  }
  .comment_id,
  .comment_date {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    color: $color-gray;
  }
  .comment_date {
    position: relative;
    @include font-family('Roboto');
    font-weight: 300;
    padding-left: 10px;
    &::before {
      position: absolute;
      top: 50%;
      left: 4px;
      margin-top: -1px;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: $color-gray;
      content: '';
    }
  }
  .comment_purchaser {
    display: inline-block;
    margin-left: 4px;
    padding: 3px 7px;
    border: 1px solid $color-primary;
    border-radius: 16px;
    font-size: 12px;
    line-height: 12px;
    color: $color-primary;
  }
  .product_comment_modify {
    position: absolute;
    bottom: 24px;
    right: 0;
    font-size: 0;
    .common_btn + .common_btn {
      margin-left: 4px;
    }
  }
}
.product_comment_empty {
  padding: 120px 20px;
  border-bottom: 1px solid map-get($color-line, line-01);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $color-gray;
}
.section_product_place {
  .product_content_heading {
    padding-bottom: 17px;
  }
}
.product_place_map {
  margin-top: 18px;
  .common_btn {
    margin-top: 16px;
  }
}
.product_map_area {
  width: $content-width;
  height: 648px;
}
.product_map {
  width: 100%;
  height: 100%;
}
.section_product_notice {
  .detail_toggle_btn {
    &[aria-expanded="false"] {
      margin-bottom: -40px;
      border-bottom: 1px solid map-get($color-line, line-01);
    }
  }
}
.section_product_addition {
  margin: 0;
}
.product_detail_toggle {
  .detail_toggle_btn {
    position: relative;
    width: 100%;
    padding: 26px 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: $color-black;
    text-align: left;
    &[aria-expanded="true"] {
      .icon_arrow_down {
        transform: rotate(180deg);
      }
      & + .detail_toggle_content {
        display: block;
      }
    }
    .icon_arrow_down {
      position: absolute;
      bottom: 28px;
      right: 0;
    }
  }
  .detail_toggle_content {
    display: none;
    padding: 16px 20px 0;
  }
  .detail_toggle_title {
    margin: 40px 0 16px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    color: $color-black;
    &:first-child {
      margin-top: 0;
    }
  }
  .detail_toggle_subtit {
    margin: 16px 0 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $color-black;
  }
  .detail_toggle_table {
    margin: 17px 0 12px;
    table {
      width: 100%;
      border-top: 1px solid map-get($color-line, line-02);
      text-align: left;
      th,
      td {
        padding: 14px 15px;
        border-bottom: 1px solid map-get($color-line, line-01);
        background: $color-white;
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
      }
      thead {
        th {
          border-color: map-get($color-line, line-02);
          color: $color-gray;
          text-align: center;
        }
      }
      tbody {
        th,
        td {
          color: $color-black;
        }
        th {
          background: map-get($color-background, bg-01);
        }
      }
      .align_center {
        text-align: center;
      }
    }
  }
  .detail_bullet_item {
    position: relative;
    padding-left: 6px;
    &::before {
      position: absolute;
      top: 9px;
      left: 0;
      width: 2px;
      height: 2px;
      background-color: $color-black;
      content: '';
    }
  }
  .detail_number_list {
    counter-reset: number;
  }
  .detail_number_item {
    padding-left: 14px;
    text-indent: -14px;
    &::before {
      display: inline-block;
      min-width: 14px;
      text-indent: 0;
      box-sizing: border-box;
      counter-increment: number;
      content: counter(number)'.';
    }
  }
}
.section_product_recommend {
  margin-top: 80px;
}
.section_product_summary {
  visibility: hidden;
  position: fixed;
  top: -124px;
  width: 100%;
  background: $color-white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  z-index: map-get($z-index, nav-fixed);
  opacity: 0;
  transition: visibility 0.3s, top 0.3s, opacity 0.3s, box-shadow 0.3s;
  &.is-fixed {
    visibility: visible;
    top: 0;
    opacity: 1;
  }
  &.is-stuck {
    box-shadow: none;
  }
}
.product_detail_summary {
  display: flex;
  margin: 0 auto;
  padding: 14px 0;
  width: $content-width;
}
.product_summary_imgbox {
  position: relative;
  overflow: hidden;
  flex: none;
  margin-right: 27px;
  width: 78px;
  height: 96px;
  border-radius: 10px;
  background: map-get($color-background, bg-01);
  @include img-border;
}
.product_summary_img {
  width: 100%;
  height: 100%;
}
.product_summary_info {
  flex: 1;
  padding-right: 15px;
}
.product_summary_title {
  @include multi-ellipsis(1);
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: $color-black;
}
.product_summary_sideinfo {
  margin-top: 1px;
  font-size: 0;
  line-height: 0;
  color: $color-gray;
}
.product_summary_place {
  font-size: 14px;
  line-height: 22px;
}
.product_summary_period {
  position: relative;
  padding-left: 10px;
  @include font-family('Roboto');
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  &::before {
    position: absolute;
    top: 50%;
    left: 4px;
    margin-top: -2px;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: map-get($color-type, gray-01);
    content: '';
  }
}
.product_summary_dropdown {
  display: flex;
  margin-top: 3px;
  .common_dropdown {
    flex: 1;
    & + .common_dropdown {
      margin-left: 6px;
    }
  }
}
.product_summary_btnarea {
  display: flex;
  flex-flow: wrap;
  gap: 6px 0;
  position: relative;
  margin: auto 0 0 auto;
  padding-left: 15px;
  max-width: 287px;
  font-size: 0;
  box-sizing: border-box;
  &::before {
    position: absolute;
    left: -1px;
    bottom: 5px;
    width: 1px;
    height: 32px;
    background: map-get($color-line, line-02);
    content: '';
  }
  .common_btn {
    flex: 1;
    width: 155px;
    > span {
      font-size: 12px;
      line-height: 16px;
    }
    & + .common_btn {
      margin-left: 6px;
    }
    &:nth-child(3):last-child {
      margin: 0;
      flex: 0 0 100%;
    }
  }
}

.dropdown_expand_btn,
.common_dropdown_btn {
  &.sat {
    color: #3D85B9;
  }
  &.sun {
    color: #DD6045;
  } 
}

// trip
.page_detail_trip {
  .section_product_info {
    padding-bottom: 30px;
  }
  .product_info_list {
    padding: 14px 0 0;
    li:first-child {
      align-items: center;
      .product_info_desc {
        flex: inherit;
      } 
      .flag_area {
        position: relative;
        top: 1px;
        margin: 0;
      }

  .product_detail_info {
    &:first-child {
      flex: 0;
    }
  }
  .product_manager_info {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .manager_profile {
      margin-left: 20px;
      font-size: 12px;
      line-height: 18px;
    }
    .manager_name {
      display: block;
      dt, dd {
        display: inline-block;
      }
      dd {
        font-weight: 700;
      }
    }
    .manager_phone, .manager_mail {
      display: inline-block;
      @include font-family('Roboto');
      letter-spacing: 0;
    }
    .manager_mail {
      margin-left: 10px;
    }
  }
  .product_manager_img {
    width: 52px;
    height: 52px;
    border-radius: 100%;
  } 
  .product_info_list {
    padding: 14px 0 0;
    .product_info_item {
      &:first-child {
        align-items: center;
        .product_info_desc {
          flex: inherit;
        } 
        .flag_area {
          position: relative;
          top: 1px;
          margin: 0;
        }
      }
    }
    .product_info_desc_sub {
      font-size: 13px;
    }
    .product_info_table {
      border-top: 0;
      th, td {
        font-size: 13px;
      }
      th {
        padding: 11px 15px;
      }
      td {
        text-align: right;
      }
      thead {
        th {
          text-align: center;
          border-bottom: 0;
        }
      }
        span {
          @include font-family('Roboto');
        }
      }
      thead th {
        text-align: center;
        border-bottom: 0;
      }
      tbody, tfoot {
        th {
          background: $color-white;
        }
        td {
          padding: 14px 25px 14px 10px;
        }
      }
      tfoot td {
        font-weight: 700;
      }
    }
  }
  .product_detail_gallery {
    width: 405px;
    margin-right: 40px;
  }
  .product_detail_imgbox {
    height: 405px;
    margin-right: 0;
  }
  .product_detail_img_list {
    display: flex;
    margin-top: 10px;
  }
  .product_detail_img_item {
    position: relative;
    width: 25%;
    height: 63px;
    border-radius: 6px;
    overflow: hidden;
    + .product_detail_img_item {
      margin-left: 10px;
    }
    img {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: auto;
    }
  }
  .product_detail_option {
    .btn_wrap {
      button + button {
        margin-left: 4px;
      }
    }
  }
  .section_product_reserve {
    z-index: 301;
  }
  .product_reserve_util {
    align-items: center;
    margin-top: 0;
    padding: 16px 33px 16px 41px;
    border: 1px solid map-get($color-type, gray-02);
    border-radius: 10px;
  }
  .product_reserve_dropdown_list {
    display: flex;
  }
  .product_reserve_dropdown_item {    
    + .product_reserve_dropdown_item {
      margin-left: 30px;
    }
    .common_dropdown {
      min-width: 104px;
    }
  }
  .product_reserve_dropdown_txt {
    display: inline-block;
    width: 55px;
    color: $color-gray;
    font-size: 15px;
  }
  .product_reserve_btnarea {
    .btn_primary {
      width: 222px;
    }
  }
  .section_product_tab {
    top: 0;
  }
}
}

.trip_schedule_area {
  position: relative;
  padding-bottom: 50px;
}
.trip_ticket_box {
  position: relative;
  display: flex;
  height: 130px;
  padding: 20px 28px;
  border: 1px solid map-get($color-type, gray-01);
  border-radius: 6px;
  box-sizing: border-box;
  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 1px;
    height: 100%;
    border-left: 1px dashed map-get($color-type, gray-01);
    content: '';
  }
  &.type_flight {
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 21px;
      height: 21px;
      background-image: svg-load("../../img/svg/icons/airplane.svg");
      transform: translate(-50%, -50%);
      content: '';
    }
  }
}
.trip_ticket_info {
  width: 50%;
  > span {
    display: block;
    line-height: 16px;
  }
  .state {
    margin-bottom: 10px;
    color: $color-gray;
    font-size: 13px;
  }
  .date, .date_sub, .vehicle {
    @include font-family('Roboto');
  }
  .date_sub {
    color: map-get($color-type, gray-01);
    font-size: 11px;
  }
  .vehicle {
    font-size: 11px;
  }
  &.depart {
    text-align: left;
    .date_sub {
      margin-top: 4px;
    }
    .vehicle {
      margin-top: 8px;
    }
  }
  &.arrive {
    text-align: right;
    .date_sub {
      margin-bottom: 2px;
    }
    .vehicle {
      margin-top: 10px;
    }
  }  
}
.trip_type_area {
  margin-top: -8px;
  margin-bottom: 20px;
  .common_dropdown {
    width: 100%;
    padding-top: 4px;
  }
  .dropdown_expand_btn,
  .common_dropdown_btn {
    font-size: 13px;
  }
}

.section_product_detail_trip {
  .product_template {
    margin-bottom: 20px;
  }
  .product_template_img {
    border-radius: 10px;
  }
  .product_benefit {
    margin-top: 20px;
    padding: 31px 19px;
    border: 1px solid map-get($color-type, gray-01);
    border-radius: 10px;
  }
  .product_benefit_title {
    color: $color-gray;
    font-size: 16px;
  }
  .product_benefit_list {
    margin-top: 21px;
  }
  .product_benefit_item {
    display: flex;
    + .product_benefit_item {
      margin-top: 7px;
    }
    .flag_area {
      flex-shrink: 0;
    }
  }
  .product_benefit_txt {
    margin-left: 7px;
    color: $color-gray;
  }
  .product_check_point {
    display: flex;
    margin-top: 21px;
  }
  .product_check_box {
    width: 50%;
    + .product_check_box {
      margin-left: 20px;
    }
  }
  .product_check_title {
    font-size: 16px;
  }
  .product_check_list {
    margin-top: 10px;
    padding: 20px;
    border-radius: 10px;
    background: map-get($color-background, bg-02);
  }
  .product_check_item {
    color: $color-gray;
    line-height: 18px;
    + .product_check_item {
      margin-top: 10px;
    }
    &.sub_desc {
      font-size: 13px;
    }
  }
}

.section_another_depart {
  .product_detail_schedule {
    display: flex;
    padding: 13px 44px 0;
  }
  .calender {
    width: 306px;
    padding-right: 50px;
    border-right: 1px solid map-get($color-line, line-01);
  }
  .calender_desc {
    padding: 0 10px;
    span {
      font-size: 10px;
      + span {
        margin-left: 16px;
      }
      &::before {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 4px;
        border: 1px solid $color-black;
        border-radius: 100%;
        box-sizing: border-box;
        content:''
      }
    }
    .reserve_depart::before {
      border-color: $color-primary;
    }
    .reserve_limit::before {
      border-color: $color-primary;
      background-color: $color-primary;
    }
    .reserve_call::before {
      border-color: map-get($color-type, gray-02);
    }
    .reserve_close::before {
      border-color: map-get($color-type, gray-04);
      background-color: map-get($color-type, gray-04);
    }
  }
  .product_reserve {
    width: 540px;
    margin-left: 50px;
    &.is-active {
      .product_info_table {
        max-height: none;
      }
      .product_reserve_btnmore {
        .icon_arrow_down {
          transform: rotate(180deg);
        }
      }
    }
  }
  .product_reserve_btnmore {
    width: 100%;
    padding: 8px 0;
  }
  .product_info_table {
    max-height: 570px;
    border-top-color: map-get($color-line, line-01);
    border-bottom: 1px solid map-get($color-line, line-01);
    overflow: hidden;
    th, td {
      padding: 12px 15px;
      text-align: center;
    }
    th {
      color: $color-gray;
      background-color: $color-white;
    }
    td {
      border-bottom: 0;
      &:nth-child(1),
      &:nth-child(2) {
        @include font-family('Roboto');
        font-weight: 500;
      }
      .state_depart {
        color: $color-primary;
        font-size: 13px;
      }
      .common_btn {
        width: 100%;
      }
      .btn_ghost_secondary {
        &:disabled,
        &[aria-disabled=true] {
          border-color: $color-white;
        }
      }
    }
  }
  .react-datepicker__header {
    .calendar_custom_header {
      .calendar_nav {
        width: 120px;
        margin: 0 auto;
      }
    }
  }
  .react-datepicker__day-names {
    height: 28px;
  }
  .react-datepicker__month {
    width: auto;
  }
  .react-datepicker__week {
    margin: 10px 0;
  }
  .react-datepicker__day {
    &:first-child,
    &:last-child {
      color: $color-black;
    }
    &.react-datepicker__day--disabled {
      color: map-get($color-type, gray-04);
    }
    &.react-datepicker__day--outside-month {
      opacity: 0;
    }
    &.react-datepicker__day--ing {
      border-color: $color-black;
    }
    &.react-datepicker__day--depart {
      color: $color-primary;
      border-color: $color-primary;
    }
    &.react-datepicker__day--limit {
      color: $color-white;
      border-color: $color-primary;
      background-color: $color-primary;
    }
    &.react-datepicker__day--call {
      color: map-get($color-type, gray-02);
    }
  }
}

.section_recommend_schedule {
  .product_schedule_template {
    .product_schedule_title {
      margin: 40px 0 26px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }
    .schedule_box {
      border: 1px solid map-get($color-line, line-02);
      border-radius: 10px;
      box-sizing: border-box;
      + .schedule_box {
        margin-top: 20px;
      }
    }
    .schedule_heading {
      position: relative;
      padding: 17px 20px 14px 160px;
      background: map-get($color-background, bg-01);
      border-bottom : 1px solid map-get($color-line, line-02);
      border-radius: 10px 10px 0 0;
    }
    .schedule_badge {
      position: absolute;
      top: 22px;
      left: 20px;
      width: 119px;
      padding: 5px 0 7px;
      border-radius: 36px;
      background: $color-black;
      color: $color-white;
      text-align: center;
    }
    .schedule_title {
      display: block;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: $color-black;
    }
    .schedule_desc {
      font-size: 14px;
      line-height: 22px;
      color: $color-gray;
      + .schedule_desc {
        margin-left: 134px;
      }
    }
    .schedule_box_content {
      padding: 18px 20px;
      .product_editor {
        color: $color-black;
        .product_editor_item {
          margin-bottom: 25px;
          h6 {
            font-size: 15px;
            font-weight: 700;
          }
        }
        &:first-of-type > .product_editor_item:last-of-type{
          margin-bottom: 30px;
        }
        &:nth-of-type(3) .product_editor_item {
          margin: 10px 0;
        }
      }
      .product_editor_recommend {
        margin: 10px 0 20px;
        color: $color-primary;
        font-weight: 700;
      }
      .one_day {
        margin-bottom: 20px;
      }
    }
  }
}

.section_product_iteminfo {
  margin-top: 40px;
  & > h2 {
    font-size: 20px;
  }
}

.product_traffic, .product_price {
  margin-top: 30px;
  .product_check_title {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .basic_tbl {
    table {
      border-top: 1px solid #cfd0d7;
    }
    thead > tr > th {
      padding: 14px 0;
    }
  }
}
.product_traffic {
  .basic_tbl tbody tr td {
    padding: 17px 0 20px;
    font-weight: 700;
  }
}
.product_price {
  .basic_tbl tbody tr td {
    padding: 12px 0 15px;
  }
  .basic_tbl tbody tr:first-of-type td {
    padding: 27px 0 25px;
    font-weight: 700;
  }
  .basic_tbl tbody tr td:first-of-type {
    text-align: left;
    padding-left: 20px;
  }
  .basic_tbl tbody tr td[colspan="5"] {
    text-align: center;
    padding-left: 0;
  }
  .product_price_notice_area {
    margin: 10px 0 20px;
    background-color: #fff7f7;
    border-radius: 10px;
    text-align: center;
    & > p {
      padding: 21px 0;
      color: #eb3434;
      font-weight: 700;
    }
  }
  .product_price_detail_wrap {
    line-height: 22px;
    color: map-get($color-type, gray-01);
    font-size: 12px;
  }
}
.section_product_tourism,.section_product_shopping,.section_product_notice {
  margin-top: 50px;
  .product_content_title {
    margin-bottom: 20px;
    font-size: 20px;
  }
}
.product_accordion_frame {
  padding: 18px 20px;
  border-radius: 10px;
  background-color: map-get($color-background, bg-01);
  .product_title_wrap {
    & > h5 {
      font-size: 16px;
      font-weight: 700;
    }
    & > p {
      color: $color-gray;
    }
  }
  .product_accordion_wrap {
    margin-top: 18px;
    & > ul > li {
      padding: 8px 10px 10px 10px;
      background-color: $color-white;
      margin-bottom: 10px;
      .product_accordion_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > span {
          font-size: 15px;
          font-weight: 700;
        }
        & > button {
          width: 10px;
          height: 10px;
          background-image: svg-load('../../img/svg/icons/rank_down.svg', fill=$color-gray);
          transition: 0.3s;
          &.active {
            transform: rotate(-180deg);
          }
        }
      }
      .product_accordion_cont {
        display: none;
        &.active{
          display: block;
        }
        padding-top: 18px;
        font-size: 15px;
        line-height: 22px;
        dd {
          padding-left: 10px;
        }
        & > dl:last-of-type {
          margin: 30px 0;
        }
        .product_accordion_price_wrap {
          color: #ff5a0f;
          font-weight: 700;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      .basic_tbl_v4 {
        table {
          border: none;
        }
        tbody th, tbody td{
          padding: 0 0 10px 0;
          border-top: none;
          background-color: transparent;
          font-size: 15px;
          color: $color-black;
        }
        tbody tr:last-of-type th,tbody tr:last-of-type td {
          padding-bottom: 0;
        }
      }
    }
  }
  .product_shopping_detail {
    margin-top: 18px;
    font-size: 13px;
    color: map-get($color-type, gray-01);
    & > ul > li {
      position: relative;
      line-height: 22px;
      padding-left: 5px;
      &::before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: map-get($color-type, gray-01);
      }
    }
  }
}
.product_notice_item {
  line-height: 22px;
  margin-bottom: 20px;
  font-size: 15px;
  & > h6 {
    margin-bottom: 5px;
    font-size: 15px;
    &.include {
      margin-bottom: 0;
    }
  }
  & > table {
    width: 100%;
    margin-top: 10px;
    border-top: 1px solid #cfd0d7;
    & > thead {
      background-color: map-get($color-background, bg-02);
    }
    thead > tr > th {
      padding: 14px 0;
      color: $color-gray;
      font-size: 14px;
    }
    tbody > tr > td {
      padding: 21px 0;
      font-size: 14px;
      color: $color-gray;
      text-align: center;
      border-bottom: 1px solid map-get($color-background, bg-03);
      & > .tourism_badge {
        margin-right: 30px;
        padding: 2px 20px;
        border: 1px solid;
        font-size: 12px;
        font-weight: 700;
        &_navy {
          color: #0f41a9;
          border-color: #0f41a9;
        }
        &_yellow {
          color: #ebb102;
          border-color: #ebb102;
        }
        &_red {
          color: #F32727;
          border-color: #F32727;
        }
      }
    }
  }
}
