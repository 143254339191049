$old-svg-origin: (
  total-width: 668px,
  total-height: 629px,
  padding: 4px,
  imageSrc: '../img/old-svg.svg'
);

$old-svg-vars: (
  'allview_reserve': (
    offset-x: -540px,
    offset-y: -523px,
    width: 32px,
    height: 32px
  ),
  'arrow': (
    offset-x: -636px,
    offset-y: -424px,
    width: 16px,
    height: 16px
  ),
  'arrow_disabled': (
    offset-x: -636px,
    offset-y: -448px,
    width: 16px,
    height: 16px
  ),
  'arrow_gray': (
    offset-x: -636px,
    offset-y: -472px,
    width: 16px,
    height: 16px
  ),
  'arrow_gray_light': (
    offset-x: -636px,
    offset-y: -496px,
    width: 16px,
    height: 16px
  ),
  'arrow_medium': (
    offset-x: -184px,
    offset-y: -171px,
    width: 24px,
    height: 24px
  ),
  'arrow_medium_disabled': (
    offset-x: -516px,
    offset-y: -577px,
    width: 24px,
    height: 24px
  ),
  'arrow_medium_disabled_double': (
    offset-x: -548px,
    offset-y: -577px,
    width: 24px,
    height: 24px
  ),
  'arrow_medium_double': (
    offset-x: -580px,
    offset-y: -577px,
    width: 24px,
    height: 24px
  ),
  'arrow_medium_gray': (
    offset-x: -636px,
    offset-y: 0px,
    width: 24px,
    height: 24px
  ),
  'arrow_orange': (
    offset-x: -636px,
    offset-y: -520px,
    width: 16px,
    height: 16px
  ),
  'arrow_red': (
    offset-x: -636px,
    offset-y: -544px,
    width: 16px,
    height: 16px
  ),
  'arrow_white': (
    offset-x: -636px,
    offset-y: -568px,
    width: 16px,
    height: 16px
  ),
  'calender': (
    offset-x: -480px,
    offset-y: -443px,
    width: 46px,
    height: 46px
  ),
  'cancel_reserve_seat': (
    offset-x: -582px,
    offset-y: -486px,
    width: 44px,
    height: 44px
  ),
  'cancel_reserve_seat_selected': (
    offset-x: 0px,
    offset-y: -577px,
    width: 44px,
    height: 44px
  ),
  'cancel_reserve_zone': (
    offset-x: -52px,
    offset-y: -577px,
    width: 44px,
    height: 44px
  ),
  'cancel_reserve_zone_selected': (
    offset-x: -104px,
    offset-y: -577px,
    width: 44px,
    height: 44px
  ),
  'casting_calender': (
    offset-x: -636px,
    offset-y: -32px,
    width: 24px,
    height: 24px
  ),
  'casting_calender_on': (
    offset-x: -636px,
    offset-y: -64px,
    width: 24px,
    height: 24px
  ),
  'check': (
    offset-x: -636px,
    offset-y: -96px,
    width: 24px,
    height: 24px
  ),
  'checked': (
    offset-x: -636px,
    offset-y: -592px,
    width: 16px,
    height: 16px
  ),
  'clock': (
    offset-x: 0px,
    offset-y: -523px,
    width: 46px,
    height: 46px
  ),
  'clock_bg_black': (
    offset-x: -636px,
    offset-y: -128px,
    width: 24px,
    height: 24px
  ),
  'clock_bg_orange': (
    offset-x: -636px,
    offset-y: -160px,
    width: 24px,
    height: 24px
  ),
  'clock_black': (
    offset-x: -387px,
    offset-y: -382px,
    width: 16px,
    height: 16px
  ),
  'close': (
    offset-x: -411px,
    offset-y: -382px,
    width: 16px,
    height: 16px
  ),
  'close_gray': (
    offset-x: -636px,
    offset-y: -192px,
    width: 24px,
    height: 24px
  ),
  'close_gray_dark': (
    offset-x: -435px,
    offset-y: -382px,
    width: 16px,
    height: 16px
  ),
  'close_white': (
    offset-x: -636px,
    offset-y: -224px,
    width: 24px,
    height: 24px
  ),
  'coming_soon_text': (
    offset-x: 0px,
    offset-y: 0px,
    width: 212px,
    height: 23px
  ),
  'company': (
    offset-x: -494px,
    offset-y: -76px,
    width: 72px,
    height: 72px
  ),
  'complet_smart': (
    offset-x: -156px,
    offset-y: -577px,
    width: 32px,
    height: 32px
  ),
  'double_mark': (
    offset-x: -636px,
    offset-y: -256px,
    width: 24px,
    height: 24px
  ),
  'double_mark_gray': (
    offset-x: -54px,
    offset-y: -523px,
    width: 46px,
    height: 46px
  ),
  'down_smart': (
    offset-x: -196px,
    offset-y: -577px,
    width: 32px,
    height: 32px
  ),
  'error_smart': (
    offset-x: -236px,
    offset-y: -577px,
    width: 32px,
    height: 32px
  ),
  'etc_call': (
    offset-x: -386px,
    offset-y: 0px,
    width: 100px,
    height: 100px
  ),
  'etc_email': (
    offset-x: -386px,
    offset-y: -108px,
    width: 100px,
    height: 100px
  ),
  'etc_warnning': (
    offset-x: -386px,
    offset-y: -216px,
    width: 100px,
    height: 100px
  ),
  'facebook': (
    offset-x: -636px,
    offset-y: -320px,
    width: 18px,
    height: 18px
  ),
  'gcomplet_smart': (
    offset-x: -276px,
    offset-y: -577px,
    width: 32px,
    height: 32px
  ),
  'guide_dsc': (
    offset-x: -582px,
    offset-y: -538px,
    width: 4px,
    height: 30px
  ),
  'heart': (
    offset-x: -316px,
    offset-y: -577px,
    width: 32px,
    height: 32px
  ),
  'heart_solid': (
    offset-x: -356px,
    offset-y: -577px,
    width: 32px,
    height: 32px
  ),
  'help_card': (
    offset-x: -108px,
    offset-y: -523px,
    width: 46px,
    height: 46px
  ),
  'help_check_inquiry': (
    offset-x: -162px,
    offset-y: -523px,
    width: 46px,
    height: 46px
  ),
  'help_consult': (
    offset-x: -494px,
    offset-y: -156px,
    width: 72px,
    height: 72px
  ),
  'help_delivery': (
    offset-x: -216px,
    offset-y: -523px,
    width: 46px,
    height: 46px
  ),
  'help_discount': (
    offset-x: -270px,
    offset-y: -523px,
    width: 46px,
    height: 46px
  ),
  'help_faq': (
    offset-x: -494px,
    offset-y: -236px,
    width: 72px,
    height: 72px
  ),
  'help_find_info': (
    offset-x: -324px,
    offset-y: -523px,
    width: 46px,
    height: 46px
  ),
  'help_guide': (
    offset-x: -494px,
    offset-y: -316px,
    width: 72px,
    height: 72px
  ),
  'help_inquiry': (
    offset-x: 0px,
    offset-y: -443px,
    width: 72px,
    height: 72px
  ),
  'help_notice': (
    offset-x: -80px,
    offset-y: -443px,
    width: 72px,
    height: 72px
  ),
  'help_personal': (
    offset-x: -160px,
    offset-y: -443px,
    width: 72px,
    height: 72px
  ),
  'help_refund': (
    offset-x: -378px,
    offset-y: -523px,
    width: 46px,
    height: 46px
  ),
  'help_sport': (
    offset-x: -432px,
    offset-y: -523px,
    width: 46px,
    height: 46px
  ),
  'ico_coupon': (
    offset-x: -494px,
    offset-y: 0px,
    width: 80px,
    height: 48px
  ),
  'illegal_img': (
    offset-x: -220px,
    offset-y: 0px,
    width: 158px,
    height: 158px
  ),
  'info': (
    offset-x: -459px,
    offset-y: -382px,
    width: 16px,
    height: 16px
  ),
  'info_big': (
    offset-x: 0px,
    offset-y: -335px,
    width: 100px,
    height: 100px
  ),
  'info_orange': (
    offset-x: -108px,
    offset-y: -412px,
    width: 16px,
    height: 16px
  ),
  'int_dropdown': (
    offset-x: -132px,
    offset-y: -412px,
    width: 16px,
    height: 16px
  ),
  'int_dropdown_darkgray': (
    offset-x: -156px,
    offset-y: -412px,
    width: 16px,
    height: 16px
  ),
  'int_dropdown_w': (
    offset-x: -180px,
    offset-y: -412px,
    width: 16px,
    height: 16px
  ),
  'logo_nhn_ticketlink': (
    offset-x: -108px,
    offset-y: -382px,
    width: 87px,
    height: 22px
  ),
  'logo_nhnlink': (
    offset-x: -108px,
    offset-y: -335px,
    width: 100px,
    height: 16px
  ),
  'logo_nhnlink_w': (
    offset-x: -494px,
    offset-y: -56px,
    width: 76px,
    height: 12px
  ),
  'logo_payco': (
    offset-x: -220px,
    offset-y: -189px,
    width: 46px,
    height: 10px
  ),
  'logo_payco_disabled': (
    offset-x: -274px,
    offset-y: -189px,
    width: 46px,
    height: 10px
  ),
  'logo_payco_ticketlink': (
    offset-x: 0px,
    offset-y: -171px,
    width: 176px,
    height: 28px
  ),
  'logo_payco_w': (
    offset-x: -324px,
    offset-y: -335px,
    width: 46px,
    height: 13px
  ),
  'logo_ticketlink_ko': (
    offset-x: -494px,
    offset-y: -396px,
    width: 69px,
    height: 27px
  ),
  'logo_toss_pay': (
    offset-x: -203px,
    offset-y: -382px,
    width: 84px,
    height: 16px
  ),
  'logo_toss_pay_disabled': (
    offset-x: -295px,
    offset-y: -382px,
    width: 84px,
    height: 16px
  ),
  'marketing': (
    offset-x: 0px,
    offset-y: -207px,
    width: 120px,
    height: 120px
  ),
  'membership': (
    offset-x: -486px,
    offset-y: -523px,
    width: 46px,
    height: 46px
  ),
  'minus_black': (
    offset-x: -204px,
    offset-y: -412px,
    width: 16px,
    height: 16px
  ),
  'minus_white': (
    offset-x: -228px,
    offset-y: -412px,
    width: 16px,
    height: 16px
  ),
  'mobile_black': (
    offset-x: -582px,
    offset-y: 0px,
    width: 46px,
    height: 46px
  ),
  'mobile_white': (
    offset-x: -582px,
    offset-y: -54px,
    width: 46px,
    height: 46px
  ),
  'mypagepoint': (
    offset-x: -582px,
    offset-y: -108px,
    width: 46px,
    height: 46px
  ),
  'mypageticket': (
    offset-x: -582px,
    offset-y: -162px,
    width: 46px,
    height: 46px
  ),
  'myticket': (
    offset-x: -582px,
    offset-y: -216px,
    width: 46px,
    height: 46px
  ),
  'pc_black': (
    offset-x: -582px,
    offset-y: -270px,
    width: 46px,
    height: 46px
  ),
  'pc_white': (
    offset-x: -582px,
    offset-y: -324px,
    width: 46px,
    height: 46px
  ),
  'play_btn': (
    offset-x: -636px,
    offset-y: -346px,
    width: 14px,
    height: 18px
  ),
  'plus_black': (
    offset-x: -252px,
    offset-y: -412px,
    width: 16px,
    height: 16px
  ),
  'plus_white': (
    offset-x: -276px,
    offset-y: -412px,
    width: 16px,
    height: 16px
  ),
  'qabox': (
    offset-x: -636px,
    offset-y: -372px,
    width: 18px,
    height: 18px
  ),
  'refresh': (
    offset-x: -300px,
    offset-y: -412px,
    width: 16px,
    height: 16px
  ),
  'refresh_full': (
    offset-x: -534px,
    offset-y: -443px,
    width: 36px,
    height: 36px
  ),
  'refresh_reserve': (
    offset-x: -396px,
    offset-y: -577px,
    width: 32px,
    height: 32px
  ),
  'search_black': (
    offset-x: -324px,
    offset-y: -412px,
    width: 16px,
    height: 16px
  ),
  'search_box_black': (
    offset-x: -594px,
    offset-y: -538px,
    width: 26px,
    height: 26px
  ),
  'search_white': (
    offset-x: -636px,
    offset-y: -288px,
    width: 24px,
    height: 24px
  ),
  'seller': (
    offset-x: -128px,
    offset-y: -207px,
    width: 120px,
    height: 120px
  ),
  'service': (
    offset-x: -256px,
    offset-y: -207px,
    width: 120px,
    height: 120px
  ),
  'swiper_next': (
    offset-x: -240px,
    offset-y: -443px,
    width: 72px,
    height: 72px
  ),
  'swiper_next_active': (
    offset-x: -320px,
    offset-y: -443px,
    width: 72px,
    height: 72px
  ),
  'swiper_next_disabled': (
    offset-x: -400px,
    offset-y: -443px,
    width: 72px,
    height: 72px
  ),
  'ticket_black': (
    offset-x: -582px,
    offset-y: -378px,
    width: 46px,
    height: 46px
  ),
  'ticket_white': (
    offset-x: -582px,
    offset-y: -432px,
    width: 46px,
    height: 46px
  ),
  'today_open': (
    offset-x: -348px,
    offset-y: -412px,
    width: 16px,
    height: 16px
  ),
  'twiiter': (
    offset-x: -636px,
    offset-y: -398px,
    width: 18px,
    height: 18px
  ),
  'vip_gold': (
    offset-x: -108px,
    offset-y: -359px,
    width: 90px,
    height: 15px
  ),
  'vip_platinum': (
    offset-x: -220px,
    offset-y: -166px,
    width: 131px,
    height: 15px
  ),
  'vip_silver': (
    offset-x: -216px,
    offset-y: -335px,
    width: 100px,
    height: 14px
  ),
  'wait_ticket': (
    offset-x: 0px,
    offset-y: -31px,
    width: 202px,
    height: 132px
  ),
  'zoom_reserve': (
    offset-x: -436px,
    offset-y: -577px,
    width: 32px,
    height: 32px
  ),
  'zoomout_reserve': (
    offset-x: -476px,
    offset-y: -577px,
    width: 32px,
    height: 32px
  ),
);
@function get-ratio($width, $height) {
    @return parse-int($width / $height);
}

@function get-scale($width, $new-width) {
    @return parse-int($new-width / $width);
}

@mixin useSvg-old($image, $size: false) {
  $image-origin: $old-svg-origin;
  $image-var: map-get($old-svg-vars, $image);
  $image-path: map-get($image-origin, 'imageSrc');
  $padding: map-get($old-svg-origin, 'padding');
  $aspectRatio: calc(map-get($image-var, 'height') / map-get($image-var, 'width'));
  $sizeRatio: calc($size / map-get($image-var, 'width'));

  @if($size){
    @if(unitless($size)){
      @warn '"#{$size}" of "#{$image}" is unitless value. Please insert value with unit(px)';
    }
    @else {
      width: $size;
      height: $size * $aspectRatio;
      background-image: url($image-path);
      background-size: (map-get($image-origin, 'total-width') * $sizeRatio) (map-get($image-origin, 'total-height') * $sizeRatio);
      background-position: ((map-get($image-var, 'offset-x') - $padding) * $sizeRatio) ((map-get($image-var, 'offset-y') - $padding) * $sizeRatio);
    }
  }
  @else {
    width: map-get($image-var, 'width');
    height: map-get($image-var, 'height');
    background-image: url($image-path);
    background-size: map-get($image-origin, 'total-width') map-get($image-origin, 'total-height');
    background-position: (map-get($image-var, 'offset-x') - $padding) (map-get($image-var, 'offset-y') - $padding);
  }
  background-repeat: no-repeat;
}
