.product_grid {
  &.type_slide {
    overflow: hidden;
    .swiper-wrapper {
      flex-wrap: nowrap;
      margin-top: 0;
      .product_grid_item {
        margin-top: 0;
      }
    }
  }
}
.product_grid_list {
  display: flex;
  flex-wrap: wrap;
  margin: -50px -28px 0 0;
  &.type_col5 {
    margin: -50px -30px 0 0;
    .product_grid_item {
      margin: 50px 30px 0 0;
      width: 200px;
      &.type_loading {
        width: 100%;
      }
    }
    .product_grid_unit {
      .product_imgbox {
        width: 200px;
        height: 247px;
      }
      .product_title {
        @include ellipsis;
      }
    }
  }
}
.product_grid_item {
  margin: 50px 28px 0 0;
  width: 259px;
  &.type_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .refresh_loading {
      margin: 0;
    }
  }
}
.product_grid_unit {
  .product_link {
    display: block;
    &:hover,
    &:focus {
      .product_img {
        transform: scale(1.1);
      }
    }
  }
  .product_imgbox {
    position: relative;
    overflow: hidden;
    width: 259px;
    height: 320px;
    border-radius: 10px;
    background: url(../img/svg/common/img_default.svg) center / 54.5% no-repeat map-get($color-background, bg-01);
    z-index: 0;
    @include img-border;
    .product_img {
      width: 100%;
      height: 100%;
      transition: transform .2s cubic-bezier(.165,.84,.44,1);
    }
    .product_ranking {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 120px;
      color: $color-white;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      box-sizing: border-box;
      z-index: 1;
    }
    .product_rank {
      position: absolute;
      bottom: 0;
      left: 12px;
      @include font-family('Roboto');
      font-size: 56px;
      font-weight: 700;
      line-height: 38px;
    }
    .product_rate {
      position: absolute;
      bottom: 8px;
      right: 12px;
      @include font-family('Roboto');
      font-weight: 500;
      font-size: 0;
      line-height: 0;
      .rate_number {
        font-size: 20px;
        line-height: 28px;
      }
      .rate_unit {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
  .product_banner_imgbox {
    .product_banner_img {
      width: 100%;
      height: 100%;
    }
  }
  .product_banner_imgbox.payco_vip_zone_card {
    .product_banner_img {
      height: 334px;
    }
  }
  .product_info {
    margin-top: 16px;
    font-size: 14px;
    line-height: 22px;
  }
  .product_title {
    @include multi-ellipsis(2);
    max-height: 52px;
    font-size: 18px;
    line-height: 26px;
    color: $color-black;
  }
  .product_sideinfo {
    margin-top: 6px;
    color: $color-gray;
  }
  .product_place {
    @include ellipsis;
  }
  .product_period {
    display: block;
    @include font-family('Roboto');
    font-weight: 300;
  }
  .product_flag {
    margin-top: 10px;
  }
  .product_discount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .product_discount.payco_disco {
    align-items: flex-end;
    height:35px;
    margin-top:12px;
  }
  .product_discount.payco_disco .product_discount_ratio {
    height: 31.5px;
  }
  .product_discount_ratio {
    color: $color-primary;
  }
  .product_discount_number {
    @include font-family('Roboto');
    font-size: 40px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.04em;
  }
  .product_discount_text {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
  }
  .product_price {
    line-height: 0;
    text-align: right;
  }
  .product_origin_price {
    display: block;
    font-size: 0;
    color: map-get($color-type, gray-01);
    text-decoration: none;
    .product_price_number {
      @include font-family('Roboto');
      font-size: 14px;
      line-height: 14px;
    }
    .product_price_text {
      font-size: 13px;
      line-height: 13px;
    }
  }
  .product_discount_price {
    display: block;
    font-size: 0;
    color: $color-black;
    .product_price_number {
      @include font-family('Roboto');
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.04em;
    }
    .product_price_text {
      font-size: 16px;
      line-height: 16px;
    }
  }
}

//trip_list
.page_list_trip {
  .product_grid {
    &.type_slide {
      overflow: hidden;
      .swiper-wrapper {
        flex-wrap: nowrap;
        margin-top: 0;
        .product_grid_item {
          margin-top: 0;
        }
      }
    }
  }
  .product_grid_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 0;
    &.type_col3 {
      margin: 0 -1px 0 0;
      .product_grid_item {
        margin: 60px 0 0 0;
        width: 355px;
        position: relative;
        &.type_loading {
          width: 100%;
        }
      }
      .product_grid_item~.product_grid_item{
        margin-left: 28px;
      }
      .product_grid_unit {
        .product_imgbox {
          width: 355px;
          height: 355px;
        }
        .product_title {
          @include ellipsis;
          color: #fff;
          position: absolute;
          bottom: 25px;
          left: 20px;
          line-height: 42px;
          max-height:100%;
          font-size: 30px;
        }
      }
    }
  }
}
