@mixin multi-ellipsis($line) {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

@mixin ellipsis($width: 100%) {
  display: block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin blind {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
}

@mixin font-family($font-family) {
  font-family: $font-family, -apple-system, BlinkMacSystemFont, Apple SD Gothic Neo, 'Malgun Gothic', Arial, sans-serif;
}

@mixin img-border {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $color-black;
    border-radius: 10px;
    box-sizing: border-box;
    opacity: 0.1;
    z-index: 1;
    content: '';
  }
}

// color change filter
// base color: #242428
@mixin color-filter($color: #fff) {
  @if($color == #fff) {
    filter: brightness(0) invert(1);
  } @else if($color == #ecedf2){
    filter: hue-rotate(350deg) brightness(655%) grayscale(75%);
  } @else if($color == #cfd0d7){
    filter: hue-rotate(353deg) brightness(575%) grayscale(70%);
  }  @else if($color == #a7acb6){
    filter: hue-rotate(340deg) brightness(472%) grayscale(35%);
  }
}

// ScrollBar
@mixin scrollbar($width: 3px) {
  overflow-y: auto;
  overflow-y: overlay;
  // firefox
  scrollbar-width: thin;
  scrollbar-color: rgba(36, 36, 40, 0.4) transparent;
  // chrome, safari
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: $width*5;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: $width*2 solid transparent;
    border-radius: 100px;
    background-color: rgba(36, 36, 40, 0.4);
    background-clip: padding-box;
  }
}

@mixin absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin clearfix($pseudo: after) {
  @if($pseudo == after) {
    &::after {
      display: block;
      clear: both;
      content: '';
    }
  } @else if($pseudo == before){
    &::before {
      display: block;
      clear: both;
      content: '';
    }
  }
}

// opacity
@mixin dimmed($opacity) {
  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, $opacity);
    content: '';
  }
}

// 말줄임
@mixin keepkword() {
  word-break: keep-all;
  word-wrap: break-word;
}

// icon
$sqrt_2: 1.41421356237;
@mixin iconAngle($direction: "up", $width: 50px, $height: 25px, $thick: 2px, $color: #222, $display: inline-block, $position: relative) {
  position: $position;
  display: $display;
  width: $width;
  height: $height;
  @if ($direction == "up" or $direction == "down") {
    $size: floor($width/$sqrt_2 - 2 * $thick);
    $rate: 2 * $height/$width;
    $margin-top: round($rate * $size/(2 * $sqrt_2));
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: $size;
      height: $size;
      @if ($rate == 1) {
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
      } @else {
        -webkit-transform: translate(-50%, -50%) scale(1, $rate) rotate(45deg);
        transform: translate(-50%, -50%) scale(1, $rate) rotate(45deg);
      }
      @if ($direction == "up") {
        margin-top: $margin-top;
        border-top: $thick solid $color;
        border-left: $thick solid $color;
      } @else if ($direction == "down") {
        margin-top: -$margin-top;
        border-right: $thick solid $color;
        border-bottom: $thick solid $color;
      }
    }
  } @else if ($direction == "left" or $direction == "right") {
    $size: floor($height/$sqrt_2 - 2 * $thick);
    $rate: 2 * $width/$height;
    $margin-left: round($rate * $size/(2 * $sqrt_2));
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: $size;
      height: $size;
      @if ($rate == 1) {
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
      } @else {
        -webkit-transform: translate(-50%, -50%) scale($rate, 1) rotate(45deg);
        transform: translate(-50%, -50%) scale($rate, 1) rotate(45deg);
      }
      @if ($direction == "left") {
        margin-left: $margin-left;
        border-left: $thick solid $color;
        border-bottom: $thick solid $color;
      } @else if ($direction == "right") {
        margin-left: -$margin-left;
        border-top: $thick solid $color;
        border-right: $thick solid $color;
      }
    }
  }
}
@mixin iconArrow($direction: "up", $width: 50px, $height: 50px, $thick: 2px, $color: #222, $display: inline-block, $position: relative) {
  position: $position;
  display: $display;
  width: $width;
  height: $height;
  &:before {
    content: '';
    position: absolute;
    border-top: $thick solid $color;
    border-left: $thick solid $color;
    @if ($direction == "up" or $direction == "down") {
      $size: round($width/$sqrt_2 - $thick);
      left: 50%;
      width: $size;
      height: $size;
      @if ($direction == "up") {
        top: round($width/2);
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
      } @else if ($direction == "down") {
        bottom: round($width/2);
        -webkit-transform: translate(-50%, 50%) rotate(-135deg);
        transform: translate(-50%, 50%) rotate(-135deg);
      }
    } @else if ($direction == "left" or $direction == "right") {
      $size: round($height/$sqrt_2 - $thick);
      top: 50%;
      width: $size;
      height: $size;
      @if ($direction == "left") {
        left: round($height/2);
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        transform: translate(-50%, -50%) rotate(-45deg);
      } @else if ($direction == "right") {
        right: round($height/2);
        -webkit-transform: translate(50%, -50%) rotate(135deg);
        transform: translate(50%, -50%) rotate(135deg);
      }
    }
  }
  &:after {
    content: '';
    position: absolute;
    background-color: $color;
    @if ($direction == "up" or $direction == "down") {
      @if ($direction == "up") {
        top: round($thick/$sqrt_2);
      } @else if ($direction == "down") {
        bottom: round($thick/$sqrt_2);
      }
      left: 50%;
      width: $thick;
      height: $height - round($thick/$sqrt_2);
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
    } @else if ($direction == "left" or $direction == "right") {
      top: 50%;
      @if ($direction == "left") {
        left: round($thick/$sqrt_2);
      } @else if ($direction == "right") {
        right: round($thick/$sqrt_2);
      }
      width: $width - round($thick/$sqrt_2);
      height: $thick;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
  }
}
@mixin iconTriangle($direction: "up", $width: 20px, $height: 10px, $color: #000, $display: inline-block) {
  display: $display;
  width: $width;
  height: $height;
  &:before {
    content: '';
    display: block;
    @if ($direction == "up") {
      border-bottom: $height solid $color;
      border-left: round($width/2) solid transparent;
      border-right: round($width/2) solid transparent;
    } @else if ($direction == "down") {
      border-top: $height solid $color;
      border-left: round($width/2) solid transparent;
      border-right: round($width/2) solid transparent;
    } @else if ($direction == "left") {
      border-top: round($height/2) solid transparent;
      border-bottom: round($height/2) solid transparent;
      border-right: $width solid $color;
    } @else if ($direction == "right") {
      border-top: round($height/2) solid transparent;
      border-bottom: round($height/2) solid transparent;
      border-left: $width solid $color;
    }
  }
}
@mixin iconCancel($size: 21px, $thick: 2px, $color: #000, $padding: 0, $display: inline-block, $position: relative) {
  position: $position;
  display: $display;
  width: $size + $padding * 2;
  height: $size + $padding * 2;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: round($size * $sqrt_2 - $thick);
    height: $thick;
    background-color: $color;
  }
  &:before {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    -webkit-transform: translate(-50%, -50%) rotate(135deg);
    transform: translate(-50%, -50%) rotate(135deg);
  }
}

// skeleton image
$skeleton-colors: #dae5eb, #dad9eb, #e2d9ea, #ebd9e9, #c9e5d9, #c5cde2, #d9d9d9, #eeeade;

@mixin skeleton-image {
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin skeleton-image-container($ratio: 100%) {
  display: block;
  position: relative;
  padding-bottom: $ratio;
}

@mixin skeleton-image-background($color: nth($skeleton-colors, 1)) {
  background-color: $color;
}

@mixin skeleton-image-wrap($ratio: 100%) {
  @include skeleton-image-container($ratio);
  @include skeleton-image-background();
  @include skeleton-image();
}

@mixin skeleton-image-wraps($iterated-element, $element, $ratio: 100%) {
  #{$iterated-element $element} {
    @include skeleton-image-container($ratio);
    @include skeleton-image();
  }
  @for $i from 1 through length($skeleton-colors) {
    $c: nth($skeleton-colors, $i);
    #{$iterated-element}:nth-child(#{$i}n + 0) #{$element} {
      @include skeleton-image-background($c);
    }
  }
}

// Safe Area
// @include safeArea(true, padding, 10px 5px right left);
// @include safeArea(false, padding, 5px bottom);
//   $all: true / false(multi / single)
//   $attr: selector
//   $val...: value direction (**direction: right bottom left)
@mixin safeArea($all: false, $attr: padding, $val...) {
  @if(($attr == padding or $attr == margin) and $all == false) {
      #{$attr}-#{separateVal($val, false)}: separateVal($val, true);
      #{$attr}-#{separateVal($val, false)}: safeAresLoop($all, 'env', $attr, $val);
      #{$attr}-#{separateVal($val, false)}: safeAresLoop($all, 'constant', $attr, $val);
  } @else {
      #{$attr}: separateVal($val, true);
      #{$attr}: safeAresLoop($all, 'env', $attr, $val);
      #{$attr}: safeAresLoop($all, 'constant', $attr, $val);
  }
}