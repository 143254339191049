body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
table,
th,
td,
form,
fieldset,
legend,
textarea,
input,
select,
textarea,
button,
article,
aside,
dialog,
footer,
header,
section,
footer,
nav,
figure,
main {
  margin: 0;
  padding: 0;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

article,
aside,
dialog,
footer,
header,
section,
footer,
nav,
figure,
main {
  display: block
}

html,
body {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none
}

img,
form,
fieldset,
svg,
li {
  border: 0;
  vertical-align: top
}

ul,
ol {
  list-style: none
}

button {
  overflow: visible;
  border: 0;
  background-color: transparent;
  cursor: pointer
}

button:disabled {
  cursor: default;
}

button::-moz-focus-inner {
  padding: 0;
  margin: -1px;
}

input,
button,
select {
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

address,
caption,
em {
  font-style: normal
}

a,
a:focus,
a:active,
a:hover {
    text-decoration: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

// [개발요청]
a:hover {
  cursor: pointer;
}