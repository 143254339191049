// gnb banner
.gnb_banner {
  overflow: hidden;
  position: relative;
  height: 80px;
}
.gnb_banner_link {
  position: relative;
  display: block;
  width: 100%;
  height: 80px;
}
.gnb_banner_img {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -960px;
  width: 1920px;
  height: 80px;
}
.gnb_banner_close {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  .icon_close {
    width: 40px;
    height: 40px;
    @include color-filter($color-white);
  }
}
// hero banner (big배너)
.hero_banner {
  position: relative;
  height: 560px;
  background: map-get($color-background, bg-01);
  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: 58px;
    margin-left: -560px;
    width: 93px;
    height: 40px;
    border-radius: 40px;
    background: rgba($color: $color-black, $alpha: 0.2);
    @include font-family('Roboto');
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $color-white;
  }
  .swiper-pagination-current {
    margin-right: 5px;
    font-weight: 700;
  }
  .swiper-pagination-total {
    margin-left: 3px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 72px;
    height: 72px;
    background: svg-load('../../img/svg/icons/hero_arrow.svg') center / 100% no-repeat;
    transform: translateY(-50%);
    opacity: 0.5;
    z-index: 2;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  .swiper-button-prev {
    left: 50%;
    margin-left: -672px;
    transform: translateY(-50%) rotate(180deg);
  }
  .swiper-button-next {
    right: 50%;
    margin-right: -672px;
  }
  .swiper-slide {
    overflow: hidden;
  }
  &.type_image {
    overflow: hidden;
    height: 400px;
    .hero_thumbnail_list {
      bottom: 36px;
      left: 40px;
      margin-left: 0;
    }
    .hero_banner_link {
      height: 400px;
      background: $color-white;
    }
    .hero_banner_imgbox {
      overflow: hidden;
      width: $content-width;
      height: 400px;
      border-radius: 10px;
      background: map-get($color-background, bg-01);
    }
  }
}
.hero_thumbnail_list {
  position: absolute;
  bottom: 57px;
  left: 50%;
  margin-left: -560px;
  font-size: 0;
  z-index: 2;
}
.hero_thumbnail_item {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 55px;
  height: 68px;
  border-radius: 6px;
  background: map-get($color-background, bg-01);
  & + & {
    margin-left: 8px;
  }
}
.hero_thumbnail_img {
  width: 100%;
  height: 100%;
}
.hero_thumbnail_btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    content: '';
    .hero_thumbnail_item.is-active & {
      background: none;
      border: 2px solid $color-white;
      border-radius: 6px;
    }
  }
}
.hero_autoplay_btn {
  position: absolute;
  bottom: 60px;
  right: 50%;
  margin-right: -560px;
  width: 40px;
  height: 40px;
  z-index: 2;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: svg-load('../../img/svg/icons/banner_stop.svg') center / 10px auto no-repeat rgba(36, 36, 36, 0.6);
    border: 2px solid map-get($color-line, line-03);
    border-radius: 50%;
    box-sizing: border-box;
    content: '';
  }
  .svg_timeline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    stroke-dasharray: 119.381;
    transform: rotate(-90deg);
    animation: timeline 6s linear forwards;
  }
  .sr_off {
    display: none;
  }
  .sr_on {
    display: block;
  }
  &.animation-reset {
    .svg_timeline {
      animation: none;
      stroke-dashoffset: 119.381;
    }
  }
  &.is-stop {
    &::before {
      background-image: svg-load('../../img/svg/icons/banner_play.svg');
      background-position-x: 14px;
    }
    .svg_timeline {
      animation: none;
      stroke-dashoffset: 119.381;
    }
    .sr_off {
      display: block;
    }
    .sr_on {
      display: none;
    }
  }
}
@keyframes timeline {
  0% {
    stroke-dashoffset: 119.381;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.hero_banner_link {
  display: block;
  position: relative;
  height: 560px;
}
.hero_banner_wrap {
  height: 100%;
}
.hero_banner_imgbox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $container-width;
  height: 560px;
  transform: translate(-50%, -50%);
}
.hero_banner_img {
  width: 100%;
  height: 100%;
}
.hero_banner_txtbox {
  margin: 0 auto;
  width: $content-width;
}
.hero_banner_text {
  position: absolute;
  top: 70px;
  width: 500px;
  color: $color-white;
  &.text_black {
    color: $color-black;
  }
}
.hero_banner_category {
  display: block;
  font-size: 18px;
  line-height: 26px;
}
.hero_banner_title {
  @include multi-ellipsis(2);
  margin-top: 8px;
  max-height: 128px;
  font-size: 50px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.04em;
  white-space: pre-line;
}
.hero_banner_subtit {
  @include multi-ellipsis(2);
  margin-top: 14px;
  max-height: 48px;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.7;
  white-space: pre-line;
}
.hero_banner_maintxt {
  display: block;
  margin-top: 30px;
  @include font-family('Roboto');
  font-size: 16px;
  line-height: 22px;
}
.hero_banner_subtxt {
  display: block;
  margin-top: 1px;
  font-size: 16px;
  line-height: 24px;
}
// hero banner (middle)
.hero_banner.middle_banner {
  width: 1120px;
  height: 400px;
  border-radius: 10px;
  .hero_banner_link {
    height: 400px;
  }
  .hero_banner_imgbox {
    width: 1120px;
    height: 400px;
  }
  .hero_banner_text {
    top: 44px;
    left: 40px;
  }
}
// promotion banner (메인big배너 하단배너)
.promotion_banner {
  overflow: hidden;
  height: 120px;
  border-radius: 10px;
  background: $color-white;
  font-size: 0;
  text-align: center;
  box-shadow: 0px 17px 21px rgba(0, 0, 0, 0.04);
}
.promotion_banner_link {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 50%;
  height: 100%;
  border-radius: 10px;
  & + & {
    &::before {
      position: absolute;
      top: 50%;
      width: 1px;
      height: 80px;
      background: map-get($color-line, line-01);
      transform: translateY(-50%);
      content: '';
    }
  }
}
.promotion_banner_img {
  width: 100%;
  height: 100%;
}
// common banner (띠배너)
.common_banner {
  overflow: hidden;
  height: 109px;
  border-radius: 10px;
}
.common_banner_link {
  display: block;
  height: 100%;
}
.common_banner_img {
  width: 100%;
  height: 100%;
}
// main wing banner
.wing_banner {
  position: absolute;
  top: 800px;
  width: 100px;
  border: 1px solid map-get($color-line, line-01);
  border-radius: 10px;
  background: $color-white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
  z-index: map-get($z-index, sticky);
  &.wing_left {
    left: 30px;
  }
  &.wing_right {
    right: 30px;
  }
  &.is-fixed {
    position: fixed;
    top: 20px;
  }
  .sports_sub & {
    top: 56px;
    &.is-fixed {
      top: 20px;
    }
  }
  &.type_bugs {
    top: 705px;
    width: 100px;
    height: 100px;
    border: none;
    background: url(../img/bugs_wingbanner@2x.png) center / 100px no-repeat transparent;
    box-shadow: none;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08));
    &.is-fixed {
      top: 20px;
    }
  }
}
.wing_banner_item {
  & + & {
    border-top: 1px solid map-get($color-line, line-01);
  }
}
.wing_banner_link {
  display: block;
  padding: 16px 0;
}
.wing_banner_img {
  width: 98px;
  height: 116px;
}
.wing_today {
  padding: 16px 0;
  border-bottom: 1px solid map-get($color-line, line-01);
  &:last-child {
    border: 0;
  }
}
.wing_today_title {
  margin-bottom: 6px;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
}
.wing_today_link {
  display: block;
  overflow: hidden;
  position: relative;
  width: 74px;
  height: 91px;
  margin: 0 auto;
  border-radius: 10px;
  @include img-border;
}
.wing_today_img {
  width: 100%;
  height: 100%;
}
.wing_today_util {
  width: 74px;
  height: 26px;
  margin: 11px auto 0;
  border: 1px solid map-get($color-line, line-04);
  border-radius: 4px;
  font-size: 0;
}
.wing_today_btn {
  position: relative;
  width: 50%;
  height: 100%;
  font-size: 0;
  line-height: 0;
}
.wing_bugs_link {
  display: block;
  height: 100%;
}