.pagination {
  margin-top: 30px;
}
.pagination_list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination_item {
  margin: 0 1px;
}
.pagination_link,
.pagination_current {
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  @include font-family('Roboto');
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
}
.pagination_link {
  background: $color-white;
  color: map-get($color-type, gray-01);
  &:hover,
  &:focus {
    color: $color-black;
  }
}
.pagination_current {
  background: $color-black;
  color: $color-white;
  cursor: default;
}
.pagination_nav {
  display: block;
  width: 30px;
  height: 30px;
  background: center / 24px no-repeat;
  @include color-filter(map-get($color-line, line-02));
  &:hover,
  &:focus {
    filter: none;
  }
}
.pagination_nav_first,
.pagination_nav_last {
  background-image: svg-load('../../img/svg/icons/arrow_right_double.svg');
}
.pagination_nav_prev,
.pagination_nav_next {
  background-image: svg-load('../../img/svg/icons/arrow_right.svg');
}
.pagination_nav_first,
.pagination_nav_prev {
  transform: rotate(180deg);
}
.pagination_nav_prev {
  margin-right: 3px;
}
.pagination_nav_next {
  margin-left: 3px;
}