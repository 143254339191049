// list
.page_list {
}
.page_heading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 46px;
  padding-top: 64px;
  .page_title {
    flex: none;
    background: none;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.05em;
    color: $color-black;
    & + .common_tab.type_underline {
      margin: 0 -10px -3px 0;
    }
  }
}
.section_list_title {
  .common_tab.type_linebox {
    margin-bottom: 40px;
  }
}
.section_list_filter {
  position: sticky;
  top: 0;
  min-height: 60px;
  background-color: $color-white;
  z-index: map-get($z-index, sticky);
  .common_dropdown {
    &:only-child {
      margin-left: auto;
    }
  }
}
.list_filter_depth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 0 24px;
  .section_heading {
    margin-bottom: 0;
  }
}
.section_list_hero {
  + .section_banner_middle {
    margin-top: 30px;
  }
}
.section_list_attention {
  margin-top: 70px;
}
.attention_wrap {
  .product_grid_list {
    .product_grid_unit {
      .product_title {
        @include multi-ellipsis(2);
        font-weight: 700;
        white-space: normal;
      }
    }
  }
}
.section_list_ranking {
  margin-top: 80px;
  padding: 60px 0 80px;
  width: auto;
  background: map-get($color-background, bg-02);
}
.section_list_open {
  margin-top: 80px;
  + .section_list_recommend {
    padding-top: 60px;
    border-top: 1px solid map-get($color-line, line-01);
  }
  .product_period {
    @include font-family('Notosans');
    color: $color-primary;
  }
}
.section_list_recommend {
  margin-top: 80px;
}
.section_list_region {
  position: relative;
  margin-top: 80px;
  padding-top: 60px;
  width: auto;
  background: map-get($color-background, bg-02);
  &:last-child {
    &::before {
      position: absolute;
      bottom: -160px;
      left: 0;
      width: 100%;
      height: 160px;
      background: map-get($color-background, bg-02);
      content: '';
    }
  }
  .common_tab {
    margin-bottom: 20px;
  }
  .common_data_none {
    margin-bottom: 0;
    padding-bottom: 120px;
  }
}
.section_list_genre {
  & + & {
    margin-top: 60px;
    padding-top: 60px;
    border-top: 1px solid map-get($color-line, line-01);
  }
}
.page_bugs_lounge {
  .hero_banner_maintxt {
    @include multi-ellipsis(2);
    @include font-family('Notosans');
  }
}
.section_bugs_live {
  margin-top: 80px;
}
.section_bugs_premium {
  margin-top: 60px;
  padding-top: 60px;
  border-top: 1px solid #ecedf2;
}

.common_data_none {
  margin: 120px 20px;
  text-align: center;
  &.type_page_center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    padding: 0 20px;
    width: $content-width;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
  }
  .text {
    font-size: 16px;
    line-height: 24px;
    color: $color-gray;
  }
}

@-webkit-keyframes ani_skeleton {
  0% {
    background-position: 250% 0
  }
  100% {
    background-position: -250% 0
  }
}

.skeleton_container {
  position: relative;
  .ui_skeleton {
    -webkit-animation-duration: 1.75s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: ani_skeleton;
    -webkit-animation-timing-function: linear;
    background-image: linear-gradient(120deg, rgba(238,239,241,1) 40%, rgba(246,247,248,1) 48%, rgba(246,247,248,1) 52%, rgba(238,239,241,1) 60%);
    background-repeat: no-repeat;
    background-size: 400% auto;
  }
  .skeleton_banner {
    background-color: #eeeff1;
    overflow: hidden;
  }
  .skeleton_grid {
    display: flex;
    flex-wrap: wrap;
    &.type_col5 {
      .skeleton_item {
        width: 200px;
        height: 345px;
        + .skeleton_item {
          padding-left: 30px;
        }
        &:nth-child(5n+1) {
          padding-left: 0;
        }
      }
      .skeleton_img {
        width: 100%;
        height: 247px;
        border-radius: 10px;
        overflow: hidden;
      }
      .skeleton_txt {
        margin-top: 16px;
        span {
          display: block;
          height: 24px;
          border-radius: 2px;
          &:nth-child(1) {
            width: 200px;
          }
          &:nth-child(2) {
            margin-top: 10px;
            width: 123px;
          }
        }
      }
    }
    &.type_col4 {
      .skeleton_item {
        width: 259px;
        height: 474px;
        + .skeleton_item {
          padding-left: 28px;
        }
        &:nth-child(4n+1) {
          padding-left: 0;
        }
        &:nth-child(n+5) {
          padding-top: 30px;
        }
      }
      .skeleton_img {
        width: 100%;
        height: 320px;
        border-radius: 10px;
        overflow: hidden;
      }
      .skeleton_txt {
        margin-top: 16px;
        span {
          display: block;
          border-radius: 2px;
          &:nth-child(1) {
            width: 100%;
            height: 24px;
          }
          &:nth-child(2) {
            margin-top: 10px;
            width: 224px;
            height: 18px;
          }
          &:nth-child(3) {
            margin-top: 10px;
            width: 148px;
            height: 18px;
          }
        }
      }
    }
  }

  .section_list_hero & .skeleton_banner {
    width: 100%;
    height: 400px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #fff;
    -webkit-animation-duration: 1.75s;
  }
  .section_banner_middle & .skeleton_banner {
    width: 100%;
    height: 109px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #fff;
    -webkit-animation-duration: 1.75s;
  }
  .section_list_attention &,
  .section_list_open & {
    .skeleton_grid {
      &.type_col5 {
        .skeleton_item {
          background-color: #fff;
        }
        .skeleton_img {
          background-color: #eeeff1;
          border: 1px solid #fff;
        }
        .skeleton_txt {
          span {
            background-color: #eeeff1;
          }
        }
      }
    }
  }
  .section_list_ranking & {
    .skeleton_grid {
      &.type_col5 {
        .skeleton_item {
          background: map-get($color-background, bg-02);
        }
        .skeleton_img {
          background-color: #eeeff1;
          border: 1px solid map-get($color-background, bg-02);
        }
        .skeleton_txt {
          span {
            background-color: #eeeff1;
          }
        }
      }
    }
  }
  .section_list_genre &,
  .section_list_recommend & {
    .skeleton_grid {
      &.type_col4 {
        .skeleton_item {
          background-color: #fff;
        }
        .skeleton_img {
          background-color: #eeeff1;
          border: 1px solid #fff;
        }
        .skeleton_txt {
          span {
            background-color: #eeeff1;
          }
        }
      }
    }
  }
  .section_list_genre & {
    margin-top: 50px;
    .skeleton_grid {
      &.type_col4 {
        .skeleton_item {
          height: 501px;
        }
      }
    }
  }
  .section_list_region & {
    .skeleton_grid {
      &.type_col4 {
        .skeleton_item {
          background: map-get($color-background, bg-02);
        }
        .skeleton_img {
          background-color: #eeeff1;
          border: 1px solid map-get($color-background, bg-02);
        }
        .skeleton_txt {
          span {
            background-color: #eeeff1;
          }
        }
      }
    }
  }
}

//list_trip
.page_list_trip {
  .page_heading{
    padding-top: 74px;
  }
  .product_grid_unit{
    .product_imgbox::before{
      background: linear-gradient(to bottom,rgba(0,0,0,0)30%,rgba(0,0,0,0.03)45%,rgba(0,0,0,0.1)65%,rgba(0,0,0,0.2)80%,rgba(0,0,0,0.3)100%,transparent);
      opacity: 1;
      border: 1px solid #2424281a;
    }
  }
  .type_capsuletype_capsule{
    margin-bottom: 20px;
  }
  .common_tab.type_underline{
    .common_tab_btn[aria-selected="true"]::before{
      bottom: 2px;
    }
  }
  .common_tab.type_dot{
    .common_tab_list {
      margin: 20px 0 30px 30px;
      max-height:22px;
      .common_tab_item{
        margin:0;
      }
      .common_tab_btn{
        padding:0;
        padding-right: 22px;
        min-height:0;
      }& [aria-selected="true"]::before {
        position: absolute;
        top: 50%;
        left: -6px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #242428;
        transform: translateY(-50%);
        content: '';
     }
    }
  }
  .section_another_depart{
    max-width: 1120px;
    margin:0 auto;
    border:1px solid #878D95;
    border-radius: 6px;
  }
  .section_trip_banner {
    .product_grid_list.type_col3{
      .product_grid_unit{
        .product_title{
          bottom: 40px;
        }
      }
    }
  }
  //캘린더 부분 
  .section_another_depart{
    padding-top:30px;
    .product_detail_schedule{
      padding:0 44px 0;
    }
    .calender{
      padding-top:15px;
      margin-bottom:30px;
    }
  }
}
.section_trip_list {
  margin-top: 60px;
  h4 {
    margin-bottom: 10px;
  }
  .trip_list_wrap {
    position: relative;
    .trip_list_bg {
      position: relative;
      width:1120px;
      height:147px;
      background-color: #3B7775;
      &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        display: block;
        z-index: 10;
      }
    }
    .bg_red {
      background-color: #FD635B;
    }
    .bg_green {
      background-color: #85AFA5;
    }
    .list_box{
      width:100%;
      max-width: 1120px;
      position:absolute;
      display: flex;
      top:0;
      left:0;
      z-index: 11;
      .trip_list_imgbx {
        width:20%;
        padding: 18px 20px 0 20px;
        img {
          border-radius: 6px;
        }
      }
      .trip_list_tit {
        width:70%;
        padding: 20px 20px 0 0; 
        color:#fff;
        font-size: 18px;
        h4 {
          font-weight: normal;
        }
        p.price{
          font-family: "Roboto",-apple-system,BlinkMacSystemFont,Apple SD Gothic Neo,"Malgun Gothic",Arial,sans-serif;
          font-size: 22px;
          font-weight:400;
          span{
            font-size: 14px;
          }
        }
      }
      a {
        position:absolute;
        bottom:40px;
        right:30px;
        width:17px;
        height:9px;
        background: url(../../../img/svg/icons/trip_big_btn.svg) no-repeat 0 0/cover;
        font-size: 14px;
        font-weight: normal;
        font-size: 0;
        &.active {
          transform: rotate(-180deg);
        }

      }
    }
  }
  .trip_pick {
    margin-top: 40px;
    .trip_pick_list {
      .trip_pick_item {
        @include clearfix($pseudo:after);
        border-bottom: 1px solid #ECEDF2;
        width:100%;
        max-width: 1120px;
        padding:10px 0;
        .trip_pick_item_tit {
          float:left;
          padding:20px;
          h4{
            font-size: 16px;
            font-weight:700;
            line-height:17px
          }
          p {
            font-size: 13px;
            line-height:20px;
            color:#62676C;
            margin-bottom: 20px;
          }
        }
        .trip_pick_item_desc {
          float: right;
          padding: 19px;
          letter-spacing: -0.04em;
          p {
            font-size: 22px;
            color:#242428;
            margin-bottom: 12px;
            text-align: right;
            font-weight:700;
            span {
              font-size: 14px;
              font-weight: 400;
            }
          }
          .btn.btn_full {
            position: relative;
            padding: 10px 51px 10px 30px;
            font-size: 14px;
            font-weight: normal;
            line-height:22px;
            &:after {
              content: '';
              position: absolute;
              width: 8px;
              height: 8px;
              border-left: 1px solid #fff;;
              border-bottom: 1px solid #FFF;
              border-radius: 1px;
              transform: rotate(-45deg);
              top:13px;
              right:32px;
            }
          }
          .btn.btn_full.on{
            border: 1px solid #dcdde1;
            background: #fff;
            color: #242428;
            line-height:22px;
            &:after {
              border-left: 1px solid #242428;
              border-bottom: 1px solid #242428;
              transform: rotate(135deg);
              top:19px;
              right:32px;
            }
          }
          
        }
      }

    }

  }

  .type_capsule{
    .common_tab {
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    min-height: 12px;
    padding: 4px 8px;
    border: 1px solid #dcdde1;
    border-radius: 40px;
    background: #fff;
    color: #242428;
  }
 } 
}
.trip_list_cont {
  margin-top:0;
}
