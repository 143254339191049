.refresh_loading {
  margin: 58px auto 0;
  width: 64px;
  height: 64px;
}
.loading_wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #000, $alpha: 0.5);
  z-index: map-get($z-index, popup);
}
.loading,
.loading_white {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
}