// 플랫폼 개편용 Old Sprite
// 추후 운영하며 각 scss 안으로 흡수될 예정
.pop_place .contents .bx_ipt_chk label:before,
.ticket_radio_item .ipt_lb_radio:before,
.place_popup input[type='checkbox'],
.select_box.type_zone .select_item.select_all .btn_select_all:before,
.ipt_radio,
.checkbox,
.chk #agree,
.chk #sand_sns,
.radio {
  width: 18px;
  height: 18px;
  border: 1px solid map-get($color-type, gray-01);
  border-radius: 50%;
  background: none;
  background-color: $color-white;
  box-sizing: border-box;
}
.ipt_radio.disabled,
.pop_place .contents .bx_ipt_chk.disabled label:before,
.place_popup tr.disabled input[type='checkbox'],
.checkbox.disabled,
.chk #agree.disabled,
.radio.disabled {
  border-color: map-get($color-line, line-04);
  background-color: map-get($color-background, bg-01);
}
.chk #agree.checked,
.pop_place .contents .bx_ipt_chk.on label:before,
.place_popup input[type='checkbox']:checked,
.select_box.type_zone .select_item.select_all .btn_select_all.is_selected:before,
.chk.checked #sand_sns,
.chk.checked #agree,
.checkbox.checked {
  border-color: $color-black;
  background: svg-load('../../img/svg/icons/checkbox_inner.svg', fill=$color-white) center / 12px 12px no-repeat $color-black;
}
.pop_place .contents .bx_ipt_chk.on.disabled label:before,
.ticket_radio_item.on.disabled .ipt_lb_radio:before,
.place_popup tr.disabled input[type='checkbox']:checked,
.checkbox.checked.disabled {
  border-color: map-get($color-line, line-04);
  background-color: map-get($color-background, bg-01);
  background-image: svg-load('../../img/svg/icons/checkbox_inner.svg', fill=map-get($color-type, gray-02));
}
.ipt_radio:checked,
.ticket_radio_item.on .ipt_lb_radio:before,
.radio.checked {
  border-color: $color-black;
  background: svg-load('../../img/svg/icons/radio_inner.svg', fill=$color-white) center / 8px 8px no-repeat $color-black;
}
.ipt_radio:checked.disabled,
.ticket_radio_item.disabled .ipt_lb_radio:before,
.radio.checked.disabled {
  border-color: map-get($color-line, line-04);
  background-color: map-get($color-background, bg-01);
  background-image: svg-load('../../img/svg/icons/radio_inner.svg', fill=map-get($color-type, gray-02));
}

.main_pop_item .link {
  background: url(../img/svg/common/img_default.svg) center / 54.5% no-repeat map-get($color-background, bg-01);
}
.quick_menu li.qmenu1 a::before {
  @include useSvg-old('help_find_info', 46px);
}
.quick_menu li.qmenu2 a::before {
  @include useSvg-old('help_check_inquiry', 46px);
}
.quick_menu li.qmenu3 a::before {
  @include useSvg-old('help_refund', 46px);
}
.quick_menu li.qmenu4 a::before {
  @include useSvg-old('help_delivery', 46px);
}
.quick_menu li.qmenu5 a::before {
  @include useSvg-old('help_discount', 46px);
}
.quick_menu li.qmenu6 a::before {
  @include useSvg-old('help_sport', 46px);
}
.quick_menu li.qmenu7 a::before {
  @include useSvg-old('help_card', 46px);
}
.help_rcont .search_help .btn_search::before {
  @include useSvg-old('search_white', 24px);
}
// 고객센터 메인
.help_rcont .help_link .help_box_guide::after {
  @include useSvg-old('help_guide', 72px);
}
.help_rcont .help_link .help_box_personal::after {
  @include useSvg-old('help_personal', 72px);
}
.help_rcont .help_link li .help_box_faq::after {
  @include useSvg-old('help_faq', 72px);
}
.help_rcont .help_link li .help_box_notice::after {
  @include useSvg-old('help_notice', 72px);
}
.help_rcont .help_link li .help_box_consult::after {
  @include useSvg-old('help_consult', 72px);
}
.help_rcont .help_link li .help_box_inquiry::after {
  @include useSvg-old('help_inquiry', 72px);
}
// 화살표(>이쪽방향 기준)
.btn_ly::before,
.wait_arrow,
.inplist .sel::after,
.slide_pagination .btn_prev.on,
.slide_pagination .btn_next.on,
.slide_pagination .btn_prev:hover,
.slide_pagination .btn_next:hover,
.select_box .seat_box .btn_seat:after,
.pop_place .area_reserve .btn_link:before,
.help_rcont .help_link .link_title::after,
.ly_add_coupon .coupon_box .coupon_more .ico,
.mypage_coupon .tab_menu_wrap .btn_blank:after,
.place_popup .bx_btn .btn_blank.history:before,
.mypage_coupon .tab_menu_wrap .coupon_short_cut:after,
.reserve_right .info_box .info_item .item_cont .btn_blank::after {
  @include useSvg-old('arrow', 16px);
}
.select_seat .btn.reserve_wait:after,
.select_seat .btn.reserve_wait.not_started:after,
.place_popup .ticket_reserve td:first-child .btn::after {
  @include useSvg-old('arrow_white', 16px);
}
.btn.reserve_wait:after,
.step_select3 .btn.reserve_wait:after {
  @include useSvg-old('arrow_orange', 16px);
}
.mypage .quick_menu_wrap .quick_menu_list .quick_menu_item .qmenu_txt .txt:after,
.sports_cont .left_sportsmenu .left_submenu li.on a:after,
.help_lmenu li.has_sub li.on a::after,
.help_lmenu li.on a::after {
  @include useSvg-old('arrow_red', 16px);
}
.btn.reserve_wait.not_started:after,
.step_select3 .btn.reserve_wait.not_started:after {
  @include useSvg-old('arrow_gray', 16px);
}
.controller .control a.first::after,
.controller .control a.next::after,
.ui-datepicker .ui-datepicker-header a.ui-datepicker-prev,
.ui-datepicker .ui-datepicker-header a.ui-datepicker-next {
  @include useSvg-old('arrow_gray_light', 16px);
}
.slide_pagination .btn_prev,
.slide_pagination .btn_next {
  @include useSvg-old('arrow_disabled', 16px);
}
.bugs_box .btn_toggle::before {
  @include useSvg-old('arrow_medium_gray', 24px);
}
// 페이지네이션
.paging a.next::after,
.paging a.prev::after,
.paginate .pre::after,
.paginate .next::after,
.venue_detail .wrap_bgslide button:hover::before,
.venue_detail .wrap_bgslide .next::before,
.venue_detail .wrap_bgslide .prev::before {
  @include useSvg-old('arrow_medium_disabled', 24px);
}
.paging a.end::after,
.paging a.first::after,
.paginate .next_more::after,
.paginate .pre_more::after {
  @include useSvg-old('arrow_medium_disabled_double', 24px);
}
.paging a.next:hover::after,
.paging a.prev:hover::after,
.paginate .pre:hover::after,
.paginate .pre.on::after,
.paginate .next:hover::after,
.paginate .next.on::after,
.venue_detail .wrap_bgslide .prev.on::before,
.venue_detail .wrap_bgslide .next.on::before,
.casting_slide_box .prev,
.casting_slide_box .next {
  @include useSvg-old('arrow_medium', 24px);
}
.paging a.end:hover::after,
.paging a.first:hover::after,
.paginate .pre_more:hover::after,
.paginate .pre_more.on::after,
.paginate .next_more:hover::after,
.paginate .next_more.on::after {
  @include useSvg-old('arrow_medium_double', 24px);
}
.social li.facebook a::before{
  @include useSvg-old('facebook', 18px);
}
.social li.twitter a::before{
  @include useSvg-old('twiiter', 18px);
}
.notes p span,
.mypage_trip .tbl_desc_area .tbl_desc_item.notice:before,
.reserve_step .bx_right .first_notice:before,
.place_popup .area_notice .title:before,
.pop_ticket .tit_info:before,
.reserve_notice_ly:before,
.ly_waiting .noti_box:before {
  @include useSvg-old('info', 18px);
}
.title_notice em,
.notes2 h6 span,
.inquiry_select_wrap .notice_box .notice_txt:before {
  @include useSvg-old('info', 16px);
}
.help_rcont .h3_line .wait_guide:before {
  @include useSvg-old('info_orange', 16px);
}
.bx_guide .btn_close::before {
  @include useSvg-old('close', 10px);
}
.mtmwrite_tbl .attach_list li a.delete,
.filelist li .bn_x,
.fileList li .bn_x,
#wrap_member .input_txt_area button.btn_del,
.ly_pop_wrap2 .pop_contents .btn_close::before,
.ly_inner_pop .ly_header .btn_close::before,
.privacy .pop_content .btn_cls::before,
.mypage_coupon .condition_box .ly_coupon_condition .btn_close::before,
.ly_clean_reserve .btn_close,
.mypage_coupon .condition_box .ly_coupon_condition .btn_close::before,
.reserve_step3 .ly_point_notice .ico_close,
.layer a.close,
.ly_season_info .btn_close,
.ly_prdt_detail .btn_close,
.ly_bugs .btn_close::before,
.ly_point_wrap .ly_header .btn_close::before,
.ly_purchase_detail .ly_header .btn_close,
.reserve_right .layer .ico_close,
.layer_step4 a.close,
.ly_terms .btn_close::before,
.ly_waiting .btn_close::before,
.bx_layer .ico_close::before,
.pop_header .btn_close::before,
.pop_place .btn_del_ipt {
  @include useSvg-old('close', 16px);
}
.bx_layer .ly_ticket_history .ico_close::before,
.ly_pop_wrap2 .pop_contents .btn_close::before,
.ly_dim_wrap .btn_close::before {
  @include useSvg-old('close', 24px);
}
.review_list .review_info a.delete::before {
  @include useSvg-old('close_gray_dark', 16px);
}
.ly_pop_daegufc .btn_close::before {
  @include useSvg-old('close_white', 24px);
}
.reserve_guide .btn_app .btn_pc_reserve_on::after {
  @include useSvg-old('pc_white', 46px);
}
.reserve_guide .btn_app .btn_pc_reserve_off::after {
  @include useSvg-old('pc_black', 46px);
}
.reserve_guide .btn_app .btn_m_reserve_on::after {
  @include useSvg-old('mobile_white', 46px);
}
.reserve_guide .btn_app .btn_m_reserve_off::after {
  @include useSvg-old('mobile_black', 46px);
}
.reserve_guide .btn_app .btn_en_reserve_guide_on::after {
  @include useSvg-old('ticket_white', 46px);
}
.reserve_guide .btn_app .btn_en_reserve_guide_off::after,
.bx_guide .btn_guide:before {
  @include useSvg-old('ticket_black', 46px);
}
.illegal_use .illegal_img {
  @include useSvg-old('illegal_img', 158px);
}
.illegal_use .punish_dsc + .guide_dsc:before {
  @include useSvg-old('guide_dsc', 4px);
}
.wait_ticket {
  @include useSvg-old('wait_ticket', 202px);
}
#wrap_popup #header .receipt_logo::after {
  @include useSvg-old('logo_nhnlink_w', 76px);
}
.cash_receipt_wrap .logo {
  @include useSvg-old('logo_nhnlink', 100px);
}
.pop_place .inner .logo,
#wrap_member #header h1 {
  @include useSvg-old('logo_ticketlink_ko', 57px);
}
.reserve_title h1,
#wrap_reserve.adult .reserve_title h1,
#wrap_reserve.error .reserve_title h1 {
  @include useSvg-old('logo_ticketlink_ko', 69px);
}
.help_rcont .partner_logo span::after {
  @include useSvg-old('logo_ticketlink_ko', 204px);
}
.addinfo_cont .ad_logo::before {
  @include useSvg-old('logo_ticketlink_ko', 246px);
}
.widthdraw_tickelink .widthdraw_title::before {
  @include useSvg-old('logo_ticketlink_ko', 106px);
}
.help_rcont .tabmenu .payco_info::before {
  @include useSvg-old('logo_payco', 54px);
}
.help_rcont .tabmenu .on .payco_info::before {
  @include useSvg-old('logo_payco_w', 54px);
}
.ly_payco_grade .tit::before {
  @include useSvg-old('logo_payco', 106px);
}
.pop_guide_wrap .guide_section .logo_payco {
  @include useSvg-old('logo_payco', 80px);
}
.reserve_guide .step .dsc .payco{
  @include useSvg-old('logo_payco', 74px);
}
.reserve_guide .en_guide_step .step_tit_box .logo_payco {
  @include useSvg-old('logo_payco', 65px);
}
.reserve_paycoselect .payco_rdo::before {
  @include useSvg-old('logo_payco', 46px);
}
.reserve_paycoselect .inp_rdo.disabled .payco_rdo::before {
  @include useSvg-old('logo_payco_disabled', 46px);
}
.reserve_paycoselect .toss_rdo::before {
  @include useSvg-old('logo_toss_pay', 84px);
}
.reserve_paycoselect .inp_rdo.disabled .toss_rdo::before {
  @include useSvg-old('logo_toss_pay_disabled', 84px);
}
.widthdraw_payco .widthdraw_title::before {
  @include useSvg-old('logo_payco', 117px);
}
.help_rcont .sub_logo {
  @include useSvg-old('logo_payco_ticketlink', 176px);
}
.help_rcont .partner_effect li.pe01::after {
  @include useSvg-old('service', 120px);
}
.help_rcont .partner_effect li.pe02::after {
  @include useSvg-old('marketing', 120px);
}
.help_rcont .partner_effect li.pe03::after {
  @include useSvg-old('seller', 120px);
}
.etc_cont .error_box::before {
  @include useSvg-old('etc_warnning', 100px);
}
.addinfo_cont .ad_effect li dt.email::before {
  @include useSvg-old('etc_email', 100px);
}
.addinfo_cont .ad_effect li dt.tel::before {
  @include useSvg-old('etc_call', 100px);
}
.addinfo_cont .ad_step_h::before,
.addinfo_cont .ad_step_h::after {
  @include useSvg-old('double_mark', 24px);
}
.ly_payco_grade .grade_info .grade.silver:after {
  @include useSvg-old('vip_silver', 100px);
}
.ly_payco_grade .grade_info .grade.gold:after {
  @include useSvg-old('vip_gold', 90px);
}
.ly_payco_grade .grade_info .grade.platinum:after {
  @include useSvg-old('vip_platinum', 131px);
}
.sports_sub .current_time em {
  @include useSvg-old('clock_black', 16px);
}
.cont_right .team_top_area .my_team::before,
.sports_cont .cont_right .rightcont_inner .team_toparea dd.my_team a::before {
  @include useSvg-old('heart', 32px);
}
.cont_right .team_top_area .my_team.on::before,
.sports_cont .cont_right .rightcont_inner .team_toparea dd.my_team a.on::before {
  @include useSvg-old('heart_solid', 32px);
}
.reserve_lst_bx ul li.today_frame .ico_todayopen .text::before {
  @include useSvg-old('today_open', 16px);
}
.mypage .quick_menu_wrap .quick_menu_list .item_payco .quick_link:before {
  @include useSvg-old('mypagepoint', 46px);
}
.mypage .quick_menu_wrap .quick_menu_list .item_reserve .quick_link:before {
  @include useSvg-old('myticket', 46px);
}
.mypage .quick_menu_wrap .quick_menu_list .item_coupon .quick_link:before {
  @include useSvg-old('mypageticket', 46px);
}
.mypage .quick_menu_wrap .quick_menu_list .item_info .quick_link:before {
  @include useSvg-old('membership', 46px);
}
.ly_add_coupon .coupon_info_table .btn_reserver_detail,
.layer_mother .help {
  @include useSvg-old('qabox', 16px);
}
.toast_box dt::before {
  @include useSvg-old('mypagepoint', 46px);
}
.calendar_wrap .calendar_box .btn_calendar.active::after {
  @include useSvg-old('casting_calender_on', 24px);
}
.calendar_wrap .calendar_box .btn_calendar::after {
  @include useSvg-old('casting_calender', 24px);
}
.casting_slide_box .casting_item.active .actor_img:before,
.casting_slide_box .casting_item:hover .actor_img:before {
  @include useSvg-old('check', 24px);
}
.company_list .area_search .ico_search {
  @include useSvg-old('company', 72px);
}
.mobile_ticket .tit:before {
  @include useSvg-old('down_smart', 32px);
}
.mobile_ticket.type1 .tit:before {
  @include useSvg-old('complet_smart', 32px);
}
.mobile_ticket.type2 .tit:before {
  @include useSvg-old('gcomplet_smart', 32px);
}
.mobile_ticket.type2.type2_1 .tit:before {
  @include useSvg-old('error_smart', 32px);
}
.cont_area .total_wrap .nodata .ico_nodata,
.help_cont .discount_nodata .ico_alarm {
  @include useSvg-old('info_big', 72px);
}
#wrap_reserve.adult .error_box em,
#wrap_reserve.error .error_box em{
  @include useSvg-old('info_big', 100px);
}
.captcha_info .btn_refresh::before {
  @include useSvg-old('refresh_full', 36px);
}
.select_count_input .btn_plus::before,
.select_count_input .btn_plus.zero_disabled:hover::before {
  @include useSvg-old('plus_black', 16px);
}
.select_count_input .btn_minus::before,
.select_count_input .btn_minus.zero_disabled:hover::before {
  @include useSvg-old('minus_black', 16px);
}
.select_count_input .btn_plus:hover::before {
  @include useSvg-old('plus_white', 16px);
}
.select_count_input .btn_minus:hover::before {
  @include useSvg-old('minus_white', 16px);
}
.seat_zone .select:before,
.seat_zone .select:hover:after {
  @include useSvg-old('checked', 16px);
}
.seat_option .btn_allview::after {
  @include useSvg-old('allview_reserve', 32px);
}
.seat_option .btn_zoom::after {
  @include useSvg-old('zoom_reserve', 32px);
}
.seat_option .btn_zoomout::after {
  @include useSvg-old('zoomout_reserve', 32px);
}
.seat_option .btn_refresh::after {
  @include useSvg-old('refresh_reserve', 32px);
}
.btn.reserve_wait:before,
.reserve_sub_title.type_waiting:before {
  @include useSvg-old('clock_bg_orange', 24px);
}
.btn.reserve_wait.not_started:before {
  @include useSvg-old('clock_bg_black', 24px);
}
.point_wrap .btn_refresh {
  @include useSvg-old('refresh', 16px);
}
.prdt_box .btn_detail .ico_detail {
  @include useSvg-old('search_box_black', 26px);
}
.selectbox .select::before,
.ly_waiting .input_area .select_box .btn_select::after,
.select_box .btn_select::after,
.bx_filter .dropdown_box.is_show .ico,
.ly_waiting.ly_waiting_confirm .info_item.type_detail .btn_more .ico,
.reserve_right .item_cont.type_detail .btn_more .ico {
  @include useSvg-old('int_dropdown', 16px);
}
.prdt_box .selectbox .select:before,
.reserve_left .selectbox a::before,
.select_with .select.color_gray2::before,
.tbl02 table tbody td .selectbox .select.color_gray2::before {
  @include useSvg-old('int_dropdown_darkgray', 16px);
}
.bx_filter .dropdown_btn .ico,
.select_seat_info .seat_header:after {
  @include useSvg-old('int_dropdown_w', 16px);
}
#wrap_member .address_info button.btn_srch,
.company_list .btn_search .ico {
  @include useSvg-old('search_black', 16px);
}
.reserve_left .layer_mother .help,
.reserve_payopt .layer_mother .help {
  @include useSvg-old('qabox', 18px);
}
.ly_waiting_type .btn_select1 .txt::before {
  @include useSvg-old('cancel_reserve_seat', 44px);
}
.ly_waiting_type .btn_select1.is_selected .txt::before {
  @include useSvg-old('cancel_reserve_seat_selected', 44px);
}
.ly_waiting_type .btn_select2 .txt::before {
  @include useSvg-old('cancel_reserve_zone', 44px);
}
.ly_waiting_type .btn_select2.is_selected .txt::before {
  @include useSvg-old('cancel_reserve_zone_selected', 44px);
}
.coupon_cont_box.nodata .nodata_txt:before {
  @include useSvg-old('ico_coupon', 80px);
}
.coupon_cont_box .event_item.nodata .nodata_txt {
  @include useSvg-old('coming_soon_text', 212px);
}
