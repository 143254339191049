// ranking
.section_ranking_weekly {
  position: relative;
  width: auto;
  .common_inner {
    position: relative;
    z-index: 1;
  }
  .page_heading {
    .page_title {
      color: $color-white;
    }
  }
  .section_heading {
    align-items: center;
    .section_title {
      color: $color-white;
    }
  }
  .common_tab.type_dot {
    .common_tab_btn {
      color: $color-white;
      opacity: 0.7;
      &[aria-selected="true"] {
        opacity: 1;
        &::before {
          background: $color-white;
        }
      }
    }
  }
  .product_grid_list {
    .product_grid_unit {
      .product_title {
        @include multi-ellipsis(2);
        white-space: normal;
      }
    }
  }
}
.ranking_weekly_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 370px;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000, $alpha: 0.4);
    content: '';
  }
}
.section_ranking_detail {
  margin-top: 80px;
  .section_heading {
    margin-bottom: 25px;
  }
  .ranking_filter {
    display: block;
    overflow: visible;
    position: relative;
    margin-top: 32px;
    height: 42px;
    z-index: 1;
    .common_dropdown {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 1;
    }
    .common_tab.type_dot {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}
.ranking_filter {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  .common_tab.type_capsule {
    float: left;
  }
  .common_tab.type_dot {
    float: right;
    .common_tab_item:last-child {
      .common_tab_btn {
        padding-right: 0;
      }
    }
  }
}
.ranking_period {
  margin: 0 auto;
  font-size: 0;
  line-height: 42px;
  text-align: center;
}
.period_calendar_btn {
  position: relative;
  padding-right: 20px;
  color: $color-black;
  &::after {
    position: absolute;
    top: 50%;
    right: 3px;
    margin-top: -2px;
    border-style: solid;
    border-color: transparent;
    border-top-color: currentColor;
    border-width: 4.5px 3.5px;
    content: '';
  }
  .icon_calendar {
    margin-right: 6px;
  }
  .period_current {
    font-size: 26px;
    font-weight: 500;
    line-height: 34px;
    color: $color-black;
    vertical-align: middle;
  }
}
.ranking_product {
  margin-top: 30px;
}
.ranking_product_table {
  width: 100%;
  border-top: 1px solid $color-black;
  thead {
    th {
      padding: 15px 10px;
      border-bottom: 1px solid map-get($color-line, line-01);
      font-size: 15px;
      font-weight: normal;
      line-height: 22px;
      color: $color-gray;
    }
  }
  tbody {
    td {
      padding: 14px 10px;
      border-bottom: 1px solid map-get($color-line, line-01);
    }
  }
  .common_data_none {
    margin: 160px 20px;
  }
}
.ranking_product_rank {
  text-align: center;
  .rank_number {
    display: block;
    @include font-family('Roboto');
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    color: $color-black;
  }
  .rank_status {
    display: block;
    font-size: 0;
    line-height: 0;
    margin-top: 10px;
  }
  .rank_static {
    display: inline-block;
    width: 12px;
    height: 12px;
    &::before {
      display: inline-block;
      margin-top: 5px;
      width: 10px;
      height: 2px;
      border-radius: 2px;
      background-color: map-get($color-type, gray-01);
      content: '';
    }
  }
  .rank_up,
  .rank_down {
    .common_icon {
      margin-right: 1px;
      width: 12px;
      height: 12px;
    }
  }
  .rank_up {
    color: $color-primary;
  }
  .rank_down {
    color: map-get($color-type, gray-01)
  }
  .rank_new {
    color: $color-primary;
    .rank_step {
      @include font-family('Notosans');
      font-size: 12px;
    }
  }
  .rank_step {
    @include font-family('Roboto');
    font-size: 16px;
    line-height: 12px;
    vertical-align: middle;
  }
}
.ranking_product_link {
  display: flex;
  align-items: center;
  &:hover,
  &:focus {
    .ranking_product_img {
      transform: scale(1.1);
    }
  }
}
.ranking_product_imgbox {
  overflow: hidden;
  position: relative;
  flex: none;
  margin-right: 16px;
  width: 84px;
  height: 104px;
  border-radius: 10px;
  background: map-get($color-background, bg-01);
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $color-black;
    border-radius: 10px;
    box-sizing: border-box;
    opacity: 0.1;
    content: '';
  }
}
.ranking_product_img {
  width: 100%;
  height: 100%;
  transition: transform .2s cubic-bezier(.165,.84,.44,1);
}
.ranking_product_title {
  font-size: 16px;
  line-height: 24px;
  color: $color-black;
}
.ranking_product_sideinfo {
  font-size: 15px;
  line-height: 22px;
  color: $color-gray;
  text-align: center;
}
.ranking_product_period {
  display: block;
  @include font-family('Roboto');
}
.ranking_product_place {
  display: block;
}
.ranking_product_rate {
  text-align: center;
  .rate_percent {
    @include font-family('Roboto');
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $color-primary;
  }
}
.ranking_product_reserve {
  .common_btn {
    width: 100%;
  }
}
.section_ranking_notice {
  margin-top: 32px;
}
.ranking_notice {
  padding: 20px 20px 26px 20px;
  border-radius: 10px;
  background: map-get($color-background, bg-01);
}
.ranking_notice_title {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-weight: normal;
  line-height: 24px;
  color: $color-black;
  .icon_info {
    margin-right: 6px;
  }
}
.ranking_criterion_list {
  margin-top: 10px;
  padding-left: 22px;
  font-size: 13px;
  line-height: 18px;
}
.ranking_criterion_item {
  display: flex;
  margin-top: 8px;
}
.ranking_criterion_title {
  flex: none;
  margin-right: 18px;
  color: $color-black;
}
.ranking_criterion_desc {
  color: $color-gray;
}