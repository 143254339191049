$color-primary: #fa2828;
$color-black: #242428;
$color-gray: #62676c;
$color-white: #fff;

$color-background: (
  bg-01: #f5f6f9,
  bg-02: #f8f9fa,
  bg-03: #ecedf2,
  bg-04: #e4e6e8
);

$color-line: (
  line-01: #ecedf2,
  line-02: #cfd0d7,
  line-03: #dfe0e5,
  line-04: #dcdde1
);

$color-type: (
  black-01: #000000,
  black-02: #151516,
  gray-01: #878d95,
  gray-02: #a7acb6,
  gray-03: #e8eaf1,
  gray-04: #ccc,
  red-01: #c82020,
  red-02: #dd6045,
  blue-01: #1a81bb,
  blue-02: #3d85b9,
  purple-01: #981ce4,
  clean-01: #1aa8bb,
  waiting-01: #f56c36
);

$z-index: (
  sticky: 10, // sticky area
  nav: 50, // lnb, dropdown, btn_move_top
  nav-fixed: 300, // product detail
  popup: 500, // popup
  modal: 1000 // modal
);

$container-width: 1920px;
$content-width: 1120px;