.common_header {
  position: relative;
  background: $color-white;
}
.header_util {
  background: map-get($color-background, bg-02);
}
.header_util_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: $content-width;
  height: 37px;
  .btn_dropdown {
    padding: 10px 13px 9px 0;
    color: map-get($color-type, gray-01);
  }
  .ico_lang {
    display: inline-flex;
    width: 16px;
    height: 16px;
    background: url(../img/ico_lang.png) 0 0 no-repeat;
    background-size: 16px auto;
    &.eng {
      background-position: 0 -20px;
    }
    &.jpn {
      background-position: 0 -40px;
    }
    &.chn {
      background-position: 0 -60px;
    }
  }
}
.header_util_left {
  margin-left: -12px;
}
.header_util_list {
  font-size: 0;
}
.header_util_item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  font-size: 0;
  & + & {
    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      width: 1px;
      height: 10px;
      background: map-get($color-line, line-02);
      transform: translateY(-50%);
      content: '';
    }
  }
  .vip_badge {
    display: inline-block;
    padding: 1px 7px;
    border: 1px solid $color-primary;
    border-radius: 16px;
    background: $color-primary;
    @include font-family('Roboto');
    font-size: 12px;
    line-height: 12px;
    color: $color-white;
    vertical-align: middle;
    box-sizing: border-box;
  }
  .logo_payco {
    width: 42px;
    height: 10px;
    vertical-align: baseline;
  }
  .btn_dropdown {
    display: inline-flex;
    gap: 0 6px;
    align-items: center;
    padding-left: 6px;
  }
}
.header_util_link {
  display: block;
  padding: 10px 6px 9px;
  font-size: 13px;
  line-height: 18px;
  color: map-get($color-type, gray-01);
  .vip_badge + & {
    display: inline-block;
    padding-left: 4px;
    vertical-align: middle;
  }
  &.user_id {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
.header_dropdown_content {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 35px;
  border: 1px solid $color-black;
  border-radius: 6px;
  background: $color-white;
  .header_util_left & {
    top: 32px;
    left: -3px;
    z-index: 100;
  }
  .header_util_right & {
    right: 0;
  }
  .btn_dropdown[aria-expanded="true"] + & {
    display: block;
  }
}
.header_language_item {
  &:first-child {
    .header_language_link {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
  &:last-child {
    .header_language_link {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
.header_language_link {
  display: flex;
  align-items: center;
  gap: 0 6px;
  padding: 0 20px 0 15px;
  height: 37px;
  font-size: 14px;
  line-height: 18px;
  color: map-get($color-type, gray-01);
  font-family: 'Pretendard';
  &:first-child,
  &:last-child {
    height: 39px;
  }
  &[aria-selected=true],
  &:hover,
  &:focus {
    background: map-get($color-background, bg-01);
    color: $color-black;
  }
}
.header_affiliate_list {
  padding: 5px;
  width: 152px;
  font-size: 0;
  line-height: 0;
  box-sizing: border-box;
}
.header_affiliate_item {
  display: inline-block;
  padding: 4px;
  width: 50%;
  text-align: center;
  box-sizing: border-box;
}
.header_affiliate_link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 63px;
  height: 14px;
  font-size: 0;
  line-height: 0;
}
.header_inner {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: $content-width;
  height: 88px;
}
.header_title {
  overflow: visible;
  background: none;
  line-height: 0;
}
.header_title_link {
  display: block;
}
.header_nav_list {
  display: flex;
  margin-left: 40px;
}
.header_nav_item {
  & + & {
    margin-left: 24px
  }
}
.header_nav_link {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.04em;
  color: map-get($color-type, gray-02);
  &[aria-current="true"] {
    color: $color-black;
  }
}
.header_banner {
  margin-left: auto;
  width: 204px;
  height: 44px;
}
.header_banner_link {
  display: block;
  height: 100%;
}
.header_banner_img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}
.header_gnb {
  position: relative;
  border-top: 1px solid map-get($color-line, line-01);
  border-bottom: 1px solid $color-black;
}
.header_gnb_inner {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: $content-width;
  height: 58px;
}
.header_gnb_area {
  display: flex;
}
.header_gnb_list {
  display: flex;
  &:first-child {
    margin-left: -13px;
  }
  & + & {
    margin-left: 23px;
    .header_gnb_item {
      &:first-child {
        .header_gnb_link {
          &::before {
            position: absolute;
            top: 50%;
            left: -11px;
            width: 1px;
            height: 14px;
            background: map-get($color-line, line-02);
            transform: translateY(-50%);
            content: '';
          }
        }
      }
    }
  }
}
.header_gnb_item {
  .header_lnb {
    top: calc(100% + 1px);
  }
}
.header_badge_wrap {
  position: relative;
  .exhibit_badge {
    position: absolute;
    left: -50px;
    top: -10px;
    right: -50px;
    text-wrap: nowrap;
    z-index: 2;
    text-align: center;
    > .link {
      display: inline-block;
      position: relative;
      padding: 4px 10px;
      border-radius: 100px;
      color: #fff;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: -0.4px;
      vertical-align: top;
      background-color: #fa2828;
      &::after{
        position: absolute;
        top: 20px;
        left: 50%;
        z-index: 1;
        transform: translateX(-50%);;
        border: 5px solid transparent;
        border-top-color: #fa2828;
        content: "";
      }
    }
  }
}
.header_gnb_link {
  display: block;
  position: relative;
  padding: 17px 11px;
  font-size: 16px;
  line-height: 24px;
  color: $color-black;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $color-primary;
  }
  &:active {
    font-weight: 700;
  }
  &[aria-current="page"] {
    font-weight: 700;
    color: $color-primary;
  }
}
.header_gnb_link.header_gnb_link_season::after{
  width: 4px;
  height: 4px;
  background-color: rgba(250,40,40,1);
  display: block;
  content: "";
  position: absolute;
  top: 20px;
  right: 7px;
  border-radius: 50%;
}
.header_search {
  position: relative;
  margin-left: auto;
}
.header_search_box {
  position: relative;
}
.header_search_input {
  width: 250px;
  padding: 7px 55px 7px 18px;
  border: 1px solid map-get($color-line, line-04);
  border-radius: 38px;
  background: $color-white;
  font-size: 14px;
  line-height: 22px;
  color: $color-black;
  box-sizing: border-box;
  outline: none;
  caret-color: $color-primary;
  &:focus {
    border-color: $color-black;
  }
  &::placeholder {
    color: map-get($color-type, gray-02);
  }
  &::-webkit-search-cancel-button {
    display: none;
  }
}
.header_search_btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px 14px;
  line-height: 0;
}
.header_search_layer {
  position: absolute;
  top: 41px;
  left: 12px;
  width: 290px;
  border: 1px solid map-get($color-line, line-01);
  border-radius: 2px;
  background: $color-white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  z-index: map-get($z-index, popup);
  .search_suggest_list {
    padding: 8px 0;
  }
  .search_suggest_link {
    display: block;
    padding: 9px 31px 9px 12px;
    &:hover,
    &:focus {
      background: map-get($color-background, bg-02)
    }
  }
  .search_suggest_text {
    @include ellipsis;
    font-size: 13px;
    line-height: 18px;
    color: $color-black;
    letter-spacing: -0.04em;
  }
  .search_suggest_keyword {
    color: $color-primary;
  }
}
.header_lnb {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-bottom: 1px solid map-get($color-background, bg-01);
  background: $color-white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.02);
  z-index: map-get($z-index, nav);
  &.is-active {
    display: block;
  }
  .header_lnb_list {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: $content-width;
    height: 46px;
  }
  .header_lnb_item {
    &:first-child {
      margin-left: -10px;
    }
  }
  .header_lnb_link {
    display: block;
    padding: 12px 10px;
    font-size: 14px;
    line-height: 22px;
    color: $color-gray;
    &:hover,
    &:focus {
      color: $color-primary;
    }
    &:active {
      font-weight: 700;
    }
  }
  .lnb {
    &_group {
      margin: 22px auto 0;
      width: 1120px;
      &:last-of-type { 
        margin-bottom: 30px; 
      }
      .tit_group {
        display: block;
        padding-bottom: 8px;
        color: #242424;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
      }
      .header_lnb {
        &_list {
          flex-flow: wrap;
          gap: 2px 20px;
          height: auto;
        }
        &_item {
          &:first-child { margin-left: 0; }
          flex: 0 0 170px;
        }
        &_link {
          display: inline-flex;
          align-items: center;
          gap: 0 2px;
          padding: 0;
          height: 36px;
          color: #242428;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          &.like {
            &::after {
              overflow: hidden;
              flex: 0 0 14px;
              width: 14px;
              height: 14px;
              font-size: 1px;
              line-height: 1px;
              color: transparent;
              background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='14' height='14' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.87572 2.98005C2.43661 2.37822 3.19723 2.04013 3.99033 2.04013C4.78343 2.04013 5.54406 2.37822 6.10495 2.98005L6.9813 3.91984L7.85766 2.98005C8.13357 2.67344 8.4636 2.42887 8.82851 2.26063C9.19342 2.09238 9.5859 2.00383 9.98304 2.00012C10.3802 1.99642 10.774 2.07764 11.1416 2.23906C11.5092 2.40047 11.8431 2.63884 12.124 2.94026C12.4048 3.24167 12.6269 3.6001 12.7773 3.99463C12.9277 4.38916 13.0033 4.81188 12.9999 5.23813C12.9964 5.66439 12.9139 6.08563 12.7572 6.47729C12.6004 6.86896 12.3726 7.22319 12.0869 7.51932L6.9813 13L1.87572 7.51932C1.315 6.91732 1 6.10093 1 5.24969C1 4.39845 1.315 3.58206 1.87572 2.98005Z' fill='%23FA2828'/%3E%3C/svg%3E%0A");
              content: "좋아요";
            }
          }
          &:hover,
          &:focus {
            color: #FA2828;
          }
        }
      }
    }
    &_banner {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      margin: 30px auto;
      width: 1120px;

      &_item {
        flex: 0 0 178px;
      }
      &_link {
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100px;
      }
      &_img {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
      }
    }
    &_no_list {
      margin: 0 auto;
      padding: 55px 0;
      width: 1120px;
      color: #62676c;
      font-weight: 400;;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }
}
