.popup_calendar_datepicker, 
.react-datepicker-popper {
  overflow: hidden;
  position: absolute !important;
  top: calc(100% + 6px) !important;
  left: 50% !important;
  border-radius: 12px;
  background: $color-white;
  transform: translateX(-50%) !important;
  z-index: map-get($z-index, popup);
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $color-black;
    border-radius: 12px;
    box-sizing: border-box;
    z-index: -1;
    content: '';
  }
}

.calendar_select_year {
  .popup_calendar_datepicker & {
    margin: 20px 1px;
    padding: 0 19px;
    width: 86px;
    max-height: 252px;
    @include scrollbar;
  }
  .calendar_select_item {
    .calendar_btn {
      width: 100%;
      height: 42px;
      border: 1px solid $color-white;
      border-radius: 6px;
      background: $color-white;
      @include font-family('Roboto');
      font-size: 14px;
      color: $color-black;
      &:hover,
      &:focus {
        border-color: $color-black;
      }
      &:disabled {
        color: map-get($color-type, gray-02);
        &:hover,
        &:focus {
          border-color: $color-white;
        }
      }
      &[aria-selected="true"] {
        border: 1px solid $color-black;
        border-radius: 6px;
        font-weight: 500;
      }
    }
  }
}

// react-datepicker library custom
.react-datepicker {
  display: block;
  border: 0;
  border-radius: 0;
  @include font-family('Notosans');
  .react-datepicker-popper & {
    margin: 20px 1px;
    padding: 0 19px;
    .calendar_custom_header {
      .calendar_nav {
        margin: 0 -4px;
      }
    }
  }
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 0;
}
.react-datepicker__month-container {
  float: none;
}
.react-datepicker__header {
  padding: 0;
  border: 0;
  border-radius: 0;
  background: $color-white;
  .calendar_custom_header {
    position: relative;
    padding-bottom: 10px;
    text-align: center;
    .calendar_nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 5px;
    }
    .calendar_title {
      position: absolute;
      top: 0;
      left: 50%;
      @include font-family('Roboto');
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: $color-black;
      transform: translateX(-50%);
    }
    .calendar_nav_btn {
      width: 24px;
      height: 24px;
      font-size: 0;
      line-height: 0;
    }
  }
}
.react-datepicker__day-names, 
.react-datepicker__week {
  display: flex; 
  justify-content: space-between;
}
.react-datepicker__day-names {
  margin: 12px 0 7px;
}
.react-datepicker__day-name {
  margin: 0;
  width: 34px;
  font-size: 12px;
  line-height: 16px;
  color: $color-black;
  &:first-child {
    color: map-get($color-type, red-02);
  }
  &:last-child {
    color: map-get($color-type, blue-02);
  }
}
.react-datepicker__month {
  margin: 0;
  width: 262px;
  &.react-datepicker__monthPicker {
    width: 270px;
  }
}
.react-datepicker__week {
  // [개발요청] 주간달력 Mouse Over Event에서 추가하는 class
  .week-group-hover {
    border-color: $color-black;
  }
}
.react-datepicker__day {
  margin: 0;
  width: 34px;
  height: 34px;
  border: 1px solid $color-white;
  border-radius: 50%;
  background: $color-white;
  @include font-family('Roboto');
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  color: $color-black;
  box-sizing: border-box;
  &:hover,
  &.react-datepicker__day--keyboard-selected {
    border-color: $color-black;
    border-radius: 50%;
    background: $color-white;
  }
  &:first-child {
    color: map-get($color-type, red-02);
  }
  &:last-child {
    color: map-get($color-type, blue-02);
  }
  &.react-datepicker__day--today {
    font-weight: 500;
  }
  &.react-datepicker__day--selected,
  &.react-datepicker__day--in-range {
    border-color: $color-black;
    background: $color-black;
    color: white;
  }
  &.react-datepicker__day--disabled {
    color: map-get($color-type, gray-02);
    &:hover,
    &.react-datepicker__day--keyboard-selected {
      border-color: $color-white;
    }
  }
}
.react-datepicker__month-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  & + & {
    margin-top: 6px;
  }
}
.react-datepicker__month {
  .react-datepicker__month-text {
    margin: 0;
    width: 86px;
    height: 42px;
    border: 1px solid $color-white;
    border-radius: 6px;
    background: $color-white;
    font-size: 14px;
    line-height: 40px;
    color: $color-black;
    box-sizing: border-box;
    &:hover,
    &.react-datepicker__month-text--keyboard-selected {
      border-color: $color-black;
    }
    &.react-datepicker__month-text--today {
      font-weight: 400;
    }
    &.react-datepicker__month--selected {
      border-color: $color-black;
      background: $color-white;
      font-weight: 500;
    }
    &.react-datepicker__month--disabled {
      color: map-get($color-type, gray-02);
      &:hover,
      &.react-datepicker__day--keyboard-selected {
        border-color: $color-white;
      }
    }
  }
}
.react-datepicker__aria-live {
  display: none;
}

// ranking custom
.page_ranking {
  .react-datepicker__day {
    &.react-datepicker__day--outside-month {
      color: map-get($color-type, gray-02);
      cursor: default;
      &:hover,
      &.react-datepicker__day--keyboard-selected {
        border-color: $color-white;
      }
      &.week-group-hover {
        &:hover,
        &.react-datepicker__day--keyboard-selected {
          border-color: $color-black;
        }
      }
    }
  }
}